import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import BaseService from "../../services/common/BaseService";

class Market extends React.Component {
    constructor(props) {
        super(props);

        this.service = new BaseService();

        this.state = {
            tokens: []
        };
    }

    componentDidMount() {
        this.getTokens();
    }

    getTokens() {
        this.service.getMarketTokens().then(res => {
            if (res.data) {
                this.setState({
                    tokens: res.data
                });
            }
        });
    }

    render() {
        return (
            <section className="contentpages">				
                <div className="container">
                    <div className="innerpagecontent pb-1 mb-0">
                        <div className="topcontentbox">
                            <h3 className="heading-title pb-2">Markets</h3>
                        </div>
                    </div>
                    <div className="panelcontentbox marketpage">
                        <div className="contentbox">
                            <div className="table-responsive sitescroll" data-simplebar>
                            <Table className="table sitetable">
                                <Thead>
                                    <Tr>
                                        <Th>#</Th>
                                        <Th>Token name</Th>
                                        <Th>Symbol</Th>
                                        <Th>Price</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                            (this.state.tokens.length > 0) ? 
                                                this.state.tokens.map((data, i) => {
                                                    return <Tr key={i}><Td>{ i+1 }</Td><Td><img src={data.logo_url} alt="logo"  className="coinicon" />{ data.name }</Td><Td>{ data.slug }</Td><Td>${ data.usd_value }</Td></Tr>
                                                })
                                            :

                                            <Tr><Td colSpan={3}>No results found.</Td></Tr>
                                        }
                                    </Tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
        );
    }
}

export default Market;