import React from "react";

class AppFooter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            emailErrorMSg: ""
        };
    }

    getYear() {
        return new Date().getFullYear();
    }

    render() {
        return (
            <footer className="footerbottom userfooter">
                <section className="footer-gray-bg fnt-reg">
                    <div className="container sitecontainer">
                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-12">
                                <p className="ftext">{this.getYear()} © { process.env.REACT_APP_NAME }. All Rights Reserved.</p>
                            </div>
                            <div className="col-md-6 col-sm-6 col-12">
                                <ul className="sociallist">
                                    <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                    <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                    <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </footer>
        )
    }
}

export default AppFooter;
