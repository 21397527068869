import React from "react";
import ReactLoading from 'react-loading';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';

import btc from './../../assets/images/color/btc.svg';
import ltc from './../../assets/images/color/ltc.svg';
import bnb from './../../assets/images/color/bnb.svg';
import trx from './../../assets/images/color/trx.svg';
import xrp from './../../assets/images/color/xrp.svg';
import usdt from './../../assets/images/color/usdt.svg';
import eth from './../../assets/images/color/eth.svg';
import link from './../../assets/images/color/link.svg';
import shib from './../../assets/images/color/shib.svg';
import swap from './../../assets/images/swap.svg';
import droparrow from './../../assets/images/drop-arrow.svg';
import ficon1 from './../../assets/images/ficon1.svg';
import ficon2 from './../../assets/images/ficon2.svg';
import ficon3 from './../../assets/images/ficon3.svg';
import metamasklogo from './../../assets/images/metamasklogo.svg';
import trustlogo from './../../assets/images/trustlogo.svg';
import hicon1 from './../../assets/images/hicon1.svg';
import hicon2 from './../../assets/images/hicon2.svg';
import hicon3 from './../../assets/images/hicon3.svg';
import hicon4 from './../../assets/images/hicon4.svg';
import appicon1 from './../../assets/images/appicon1.svg';
import appicon2 from './../../assets/images/appicon2.svg';
import mobileapp from './../../assets/images/mobileapp.png';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import $ from 'jquery';
// import './../../assets/js/endlessRiver.js';

import ConnectWallet from '../../components/connectwallet/ConnectWallet';
import TokenComponent from '../../components/token/TokenComponent';
import TokenListComponent from '../../components/token/TokenListComponent';

import eventBus from "../../events/EventBus";

import HomeService from "../../services/home/HomeService";
import SwapUtil from "../../hooks/SwapUtil";

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.swapUtilRef = React.createRef();

        this.service = new HomeService();

        this.state = {
            show: false,
            tokens: [],
            tokensBalance: [],
            isBalLoading: false,

            inputCoinShow: false,
            outputCoinShow: false,
            filteredTxtInput: localStorage.getItem("token") ? localStorage.getItem("token") : "ETH",
            filteredTxtOutput: "",
            filteredInputBalance: localStorage.getItem("balance") ? localStorage.getItem("balance") : 0,
            filteredOutputBalance: 0,
            filteredTxtInputPercBal: 0,
            filteredTxtOutputPercBal: 0,
            inputPrice: 0,
            outputPrice: 0,
            isShowInputAlert: false,
            isShowOutputAlert: false,
            showWallet: false,
            priceImpact: 0,
            slippage: 0,
            reserveIn: 0,
            reserveOut: 0,
            isSwap: false,
            tokenprice: null,
            swaptokens: null,
            tokenAllowence: 0,
            isShowInputAllowence: false,
            isShowLiqLimitAlert: false,
            slippagePerc: 0.1,

            liquidityData: null,
            exinputCoinShow: false,
            exoutputCoinShow: false,
            exfilteredTxtInput: localStorage.getItem("token") ? localStorage.getItem("token") : "ETH",
            exfilteredTxtOutput: "",
            exfilteredInputBalance: localStorage.getItem("balance") ? localStorage.getItem("balance") : 0,
            exfilteredTxtLiqtyInputContract: process.env.REACT_APP_ETH,
            exfilteredTxtLiqtyOutputContract: "",
            exfilteredOutputBalance: 0,
            exfilteredTxtInputPercBal: 0,
            exfilteredTxtOutputPercBal: 0,
            exinputPrice: 0,
            exoutputPrice: 0,
            exisShowInputAlert: false,
            exisShowOutputAlert: false,
            exisShowInputAllowence: false,
            exisShowOutputAllowence: false,
            inputAmt: 0,
            outputAmt: 0,
            total: 0,
            isShowAddLiq: false,
            approveFilterType: "",
            isAddLiqBtn: false,
            liquidityList: [],
            ispairloading: false,
            isShowLiqPair: false,
            filteredPairData: {},
            liqInBal: 0,
            liqOutBal: 0,
            pairData: {},
            poolShare: 0,
            receiveTokens: 0,
            rmLiqAllowence: false,
            isFirstLiq: false,
            // slippagePerc: 0.1,
            cms: {
                home: {
                    title: "",
                    content: ""
                },
                features: [],
                howItWorks: [],
                aboutus: "",
                faqs: []
            },
            faqs: [],
            faqSeeMore: false,
            deadline: 20,
            isPayPopup: false,
            isPaid: false,
            payAmt: 0,
            payLoading: false,
            pairs: [
                { name:"Bitcoin", coinone: "BTC", cointwo: "USDT"},
                { name:"Ethereum", coinone: "ETH", cointwo: "USDT"},
                { name:"Litecoin", coinone: "LTC", cointwo: "USDT"},
                { name:"Tether", coinone: "USDT", cointwo: "USDT"},
                { name:"Binance", coinone: "BNB", cointwo: "USDT"},
                { name:"Tron", coinone: "TRX", cointwo: "USDT"},
                { name:"Ripple", coinone: "XRP", cointwo: "USDT"}
            ]
        };

        this.showInputCoin = this.showInputCoin.bind(this);
        this.showOutputCoin = this.showOutputCoin.bind(this);

        this.exshowInputCoin = this.exshowInputCoin.bind(this);
        this.exshowOutputCoin = this.exshowOutputCoin.bind(this);

        this.examtCalInputEvent = this.examtCalInputEvent.bind(this);
        this.examtCalOutputEvent = this.examtCalOutputEvent.bind(this);
    }

    componentDidMount() {
        // $("#myul").endlessRiver();

        this.loadWebsocket();

        this.getToken();
        this.getHomeCms();
        this.getTokensBalance();

        if (this.service.address()) {
            this.getTokensBalance();
            this.getLiquidityList();
        }

        eventBus.on("connectWallet", (data) => {
            this.setState({filteredTxtInput: localStorage.getItem("token")});

            if (data.status && this.service.address()) {
                this.setState({filteredTxtInput: localStorage.getItem("token"), filteredInputBalance: localStorage.getItem("balance")}, () =>  {
                    this.getTokensBalance();
                });
            }
        });

        eventBus.on("disconnectWallet", (data) => {
            localStorage.clear();
            this.service.address();

            this.setState({filteredInputBalance: 0}, () =>  {
                this.getTokensBalance();
            });
        });
        
    }

    loadWebsocket() {
    }

    getToken() {
        this.setState({isLiqLoading: true});

        this.service.getTokens().then(res => {
            if (res.success) {
                this.setState({tokens: res.data, isLiqLoading: false});
            }
        });
    }

    getHomeCms() {
        this.service.getHomeCms().then(res => {
            if (res.data) {
                this.setState({
                    cms: {
                        home: {
                            title: res.data.home.title,
                            content: res.data.home.content
                        },
                        features: res.data.features,
                        howItWorks: res.data.howItWorks,
                        aboutus: res.data.aboutus,
                        faqs: res.data.faqs
                    },
                    faqs: res.data.faqs,
                    faqSeeMore: res.data.fagmore
                });
            }
        });
    }

    getTokensBalance() {
        var params = {
            address: this.service.address(),
            avail: this.state.filteredInputBalance
        };

        this.setState({ isBalLoading : true });

        this.service.getAllTokenBalance(params).then(res => {
            if (res.success) {
                this.setState({tokensBalance: res.data, isBalLoading: false });
            }
        });
    }

    showInputCoin = () => {
        this.setState(prevState => ({
            inputCoinShow: !prevState.inputCoinShow
        }));
    }

    exshowInputCoin = () => {
        this.setState(prevState => ({
            exinputCoinShow: !prevState.exinputCoinShow
        }));
    }

    showOutputCoin = () => {
        this.setState(prevState => ({
            outputCoinShow: !prevState.outputCoinShow
        }));
    }

    exshowOutputCoin = () => {
        this.setState(prevState => ({
            exoutputCoinShow: !prevState.exoutputCoinShow
        }));
    }

    filteredImageLink = (name, type, imgname=true) => {
        var path = "";

        if (name) {
            this.state.tokensBalance.filter(token => {
                if (token.slug === name) {
                    path = token.logo_url;
                }
            });
        }

        if (path) {
            return <><img src={path} className='coinicon' /> { imgname ? name : '' } </>;
        } else {
            if (type === "input") {
                return <><img src={eth} className='coinicon' /> { name } </>;
            } else {
                return <>Select</>
            }
        }
    }

    filteredItemEvent = (name, balance, type, usd) => {
        if (type === "input") {
            this.setState({ filteredTxtInput: name, filteredInputBalance: balance, filteredTxtInputPercBal: 0, isShowInputAlert: false, isShowOutputAlert: false });

            this.showInputCoin();

            if (name && this.service.address()) {
                this.getPairAllowence(name);
            }

            if (name && this.state.filteredTxtOutput && this.service.address()) {
                this.getTokenPrice(name, this.state.filteredTxtOutput);
            }
        } else {
            this.setState({ filteredTxtOutput: name, filteredOutputBalance: balance, filteredTxtOutputPercBal: 0, isShowInputAlert: false, isShowOutputAlert: false });

            this.showOutputCoin();

            if (name && this.state.filteredTxtInput && this.service.address()) {
                this.getTokenPrice(this.state.filteredTxtInput, name);
            }
        }
    }

    exfilteredItemEvent = (name, balance, type, usd) => {
        if (type === "input") {
            this.setState({ exfilteredTxtInput: name, exfilteredInputBalance: balance, exfilteredTxtInputPercBal: 0, exisShowInputAlert: false, exisShowOutputAlert: false });

            this.exshowInputCoin();

            if (name && this.service.address()) {
                this.getPairAllowence(name);
            }

            if (name && this.state.exfilteredTxtOutput && this.service.address()) {
                this.exgetTokenPrice(name, this.state.exfilteredTxtOutput);
            }
        } else {
            this.setState({ exfilteredTxtOutput: name, exfilteredOutputBalance: balance, exfilteredTxtOutputPercBal: 0, exisShowInputAlert: false, exisShowOutputAlert: false });

            this.exshowOutputCoin();

            if (name && this.state.exfilteredTxtInput && this.service.address()) {
                this.exgetTokenPrice(this.state.exfilteredTxtInput, name);
            }
        }
    }

    getTokenPrice = (input, output) => {
        var params = {
            input_slug: input,
            output_slug: output
        };

        this.setState({isBalLoading: true});

        this.service.getTokensPrice(params).then(res => {
            if (res.success) {
                this.setState({tokenprice: res.data, isBalLoading: false});

                var params = {
                    inputPrice: 1,
                    outputPrice: res.data.inputTokenMidPrice,
                    priceImpact: res.data.priceImpact,
                    slippage: res.data.slippage,
                    reserveIn: res.data.reserveIn,
                    reserveOut: res.data.reserveOut
                };

                this.setState(params);
            }
        });
    }

    exgetTokenPrice = (input, output) => {
        var params = {
            input_slug: input,
            output_slug: output
        };

        this.setState({isBalLoading: true});

        this.service.getTokensPrice(params).then(res => {
            if (res.success) {
                this.setState({tokenprice: res.data, isBalLoading: false});

                var params = {
                    inputPrice: 1,
                    outputPrice: res.data.inputTokenMidPrice,
                    priceImpact: res.data.priceImpact,
                    slippage: res.data.slippage,
                    reserveIn: res.data.reserveIn,
                    reserveOut: res.data.reserveOut
                };

                this.setState(params);
            }
        });
    }

    getPairAllowence = (input, output) => {
        var params = {
            input_slug: input,
            from_address: this.service.address()
        };

        this.setState({isBalLoading: true});

        this.service.getTokensAllowence(params).then(res => {
            if (res.success) {
                if (res.data) {
                    this.setState({tokenAllowence: res.data.allowence, isBalLoading: false});
                }
            }
        });
    }

    amtCalInputEvent = (event) => {
        var inputVal = event.target.value.replace(/[^0-9\.]/g,'');

        if (inputVal.split('.').length > 2) {
            return false;
        }

        this.setState({ filteredTxtInputPercBal: inputVal});
        this.customizeInputEvent(inputVal);
    }

    customizeInputEvent = (inputVal) => {
        var isShowInputAlert = false;
        var isShowOutputAlert = false;
        var isShowInputAllowence = false;

        this.inputPriceCal(inputVal);

        if (parseFloat(inputVal) > parseFloat(this.state.filteredInputBalance)) {
            isShowInputAlert = true;
        }

        if (inputVal > parseFloat(this.state.tokenAllowence) && !isShowInputAlert) {
            isShowInputAllowence = true;
        }

        if (this.state.filteredTxtInput === "ETH") {
            isShowInputAllowence = false;
        }

        var params = {
            filteredTxtInputPercBal: inputVal,
            filteredTxtOutputPercBal: this.outPercBal,
            isShowInputAlert: isShowInputAlert,
            isShowOutputAlert: isShowOutputAlert,
            isShowInputAllowence: isShowInputAllowence
        };

        this.setState(params);
    }

    inputPriceCal = (input) => {
        var isShowInputAllowence = false;
        var amountInWithFee = parseFloat(input) * 997;
        var numerator = parseFloat(amountInWithFee) * parseFloat(this.state.reserveOut);
        var denominator = (parseFloat(this.state.reserveIn) * 1000) + parseFloat(amountInWithFee);

        var output = parseFloat(numerator) / parseFloat(denominator);

        output = output - (output * (this.state.slippagePerc / 100));

        if (output === Infinity || isNaN(output)) {
            output = 0;
        }

        this.outPercBal = (output > 0) ? output.toFixed(6) : 0;
    }

    outputPriceCal = (output) => {
        var isShowInputAllowence = false;
        var isShowInputAlert = false;
        var numerator = ( parseFloat(this.state.reserveIn) * parseFloat(output) ) * 1000;
        var denominator = (parseFloat(this.state.reserveOut) - parseFloat(output)) * 997;
        var input = ((parseFloat(numerator) / parseFloat(denominator)) * 10 **(18)) + 1;

        input = input * 10 ** (-18);

        if (input === Infinity || isNaN(input)) {
            input = 0;
        }

        this.inPercBal = (input > 0) ? this.formatData(input, 6) : 0;
    }

    amtCalOutputEvent = (event) => {
        if (this.state.filteredTxtOutput) {
            var outputVal = event.target.value.replace(/[^0-9\.]/g,'');

            if (outputVal.split('.').length > 2) {
                return false;
            }

            this.outputPriceCal(outputVal);
            this.setState({ filteredTxtOutputPercBal: outputVal});

            var isShowInputAlert = false;
            var isShowOutputAlert = false;
            var isShowInputAllowence = false;
            var isShowLiqLimitAlert = false;

            if (parseFloat(this.inPercBal) > parseFloat(this.state.filteredInputBalance)) {
                isShowInputAlert = true;
            }

            if (this.inPercBal > parseFloat(this.state.tokenAllowence) && !isShowInputAlert) {
                isShowInputAllowence = true;
            }

            if (parseFloat(outputVal) > parseFloat(this.state.filteredOutputBalance)) {
                isShowOutputAlert = true;
                isShowLiqLimitAlert = false;
            }

            if (!isShowOutputAlert && parseFloat(this.inPercBal) < 0) {
                isShowLiqLimitAlert = true;
            }

            if (this.state.filteredTxtInput === "ETH") {
                isShowInputAllowence = false;
            }

            this.setState({isShowOutputAlert: isShowOutputAlert, isShowInputAlert: isShowInputAlert, isShowInputAllowence: isShowInputAllowence, filteredTxtInputPercBal: Math.abs(this.inPercBal), isShowLiqLimitAlert: isShowLiqLimitAlert});
        }
    }

    spenderApproveEvent = () => {
        var params = {
            input_slug: this.state.filteredTxtInput,
            from_address: this.service.address(),
            amount: this.state.filteredTxtInputPercBal
        };

        this.service.spenderApprove(params).then(res => {
            if (res.data) {
                this.spenderApprove(res.data);
            }
        });
    }

    spenderApprove(data) {
        const transactionParameters = {
            to: data.contract,
            from: this.service.address(),
            value: '0x0',
            data: data.data.data
        };

        this.swapUtilRef.current.spenderApprove(transactionParameters);
    }

    spenderApproveConfirmed = (data) => {
        if (data.hash) {
            toast.success(this.state.filteredTxtInput + " approved successfully");

            this.setState({isShowInputAllowence: false});
        } else {
            toast.error("Transaction Cancelled");
        }
    }

    swapConfirmationEvent = () => {
        var params = {
            input_slug: this.state.filteredTxtInput,
            output_slug: this.state.filteredTxtOutput,
            input_amt: this.state.filteredTxtInputPercBal,
            output_amt: this.state.filteredTxtOutputPercBal,
            from_address: this.service.address(),
            deadline: this.state.deadline
        };

        this.service.swapConfirmation(params).then(res => {
            if (res.data) {
                this.setState({isSwap: true});
                this.swapConfirmation(res.data);
            }
        });
    }

    swapConfirmation(data) {
        const transactionParameters = {
            to: data.to,
            from: this.service.address(),
            value: data.value,
            data: data.data
        };

        this.swapUtilRef.current.swapConfirm(transactionParameters);
    }

    swapConfirmed = (data) => {
        if (data.hash) {
            toast.success("Swap Completed Successfully");

            this.setState({isSwap: false, filteredTxtInputPercBal:0, filteredTxtOutputPercBal:0});
        } else {
            toast.error("Swap Cancelled");

            this.setState({isSwap: false, filteredTxtInputPercBal:0, filteredTxtOutputPercBal:0});
        }
    }

    swapConfirmationCancelEvent = () => {
        this.setState({isSwap: false, filteredTxtInputPercBal:0, filteredTxtOutputPercBal:0});
    }

    showConfirmSection = () => {
        return (
            this.service.address() &&
            this.state.filteredTxtInputPercBal &&
            this.state.filteredTxtOutputPercBal &&
            !this.state.isShowInputAlert &&
            !this.state.isShowOutputAlert &&
            !this.state.isShowLiqLimitAlert
        ) ? true : false;
    }

    formatData(data, decimal) {
        return parseFloat(data).toFixed(decimal);
    }

    showLiquidityBtn = () => {
        return (!this.state.exisShowInputAllowence &&
        !this.state.exisShowOutputAllowence &&
        (parseFloat(this.state.inputAmt) > 0) &&
        (parseFloat(this.state.outputAmt) > 0) &&
        !this.state.exisShowInputAlert &&
        !this.state.exisShowOutputAlert) ? true : false;
    }

    examtCalInputEvent = (event) => {
        var inAmt = event.target.value.replace(/[^0-9\.]/g,'');

        if (inAmt.split('.').length > 2) {
            return false;
        }

        var params = {
            inputVal: inAmt,
            inputOrg: this.state.exfilteredInputBalance,
            outputOrg: this.state.exfilteredOutputBalance,
            token2: this.state.liquidityData ? this.state.liquidityData.result.token2price : 0,
            token1allowance: this.state.liquidityData ? this.state.liquidityData.result.token1allowance : 0,
            token2allowance: this.state.liquidityData ? this.state.liquidityData.result.token2allowance : 0,
            isFirstL: this.state.isFirstLiq
        };

        this.service.examtCalInputEvent(params).then(res => {
            this.setState(res);
            this.quoteAddLiquidity(res.inputAmt, res.outputAmt);
        });
    }

    examtCalOutputEvent = (event) => {
        var outputVal = event.target.value.replace(/[^0-9\.]/g,'');

        if (outputVal.split('.').length > 2) {
            return false;
        }

        var params = {
            inputOrg: this.state.exfilteredInputBalance,
            outputVal: outputVal,
            outputOrg: this.state.exfilteredOutputBalance,
            token1: this.state.liquidityData ? this.state.liquidityData.result.token1price : 0,
            token2allowance: this.state.liquidityData ? this.state.liquidityData.result.token2allowance : 0,
            isFirstL: this.state.isFirstLiq
        }

        this.service.examtCalOutputEvent(params).then(res => {
            this.setState(res);
            this.quoteAddLiquidity(res.inputAmt, res.outputAmt);
        });
    }

    quote(amount1, reserve1, reserve2) {
        var amount2 = amount1 * (reserve2 / reserve1);

        return amount2;
    }

    quoteAddLiquidity(amountADesired, amountBDesired) {
        if (this.state.liquidityData.result.reserveA === 0 && this.state.liquidityData.result.reserveB === 0) {
            var amountOut = this.quoteMintLiquidity(amountADesired, amountBDesired);

            this.setState({receiveTokens: amountOut});
            this.sharePoolCal(amountOut);
        } else {
            const amountBOptimal = this.quote(amountADesired, this.state.liquidityData.result.reserveA, this.state.liquidityData.result.reserveB);

            if (amountBOptimal <= amountBDesired) {
                const amountOut = this.quoteMintLiquidity(amountADesired, amountBOptimal);

                this.setState({receiveTokens: amountOut});
                this.sharePoolCal(amountOut);
            } else {
                const amountAOptimal = this.quote(amountBDesired,this.state.liquidityData.result.reserveB,this.state.liquidityData.result.reserveA);
                const amountOut = this.quoteMintLiquidity(amountAOptimal, amountBDesired);

                this.setState({receiveTokens: amountOut});
                this.sharePoolCal(amountOut);
            }
        }
    }

    quoteMintLiquidity(amountA, amountB){
        const MINIMUM_LIQUIDITY = 1000;
        const valueA = amountA*(10**this.state.liquidityData.result.token1Decimals);
        const valueB = amountB*(10**this.state.liquidityData.result.token2Decimals);
        const reserve_a = this.state.liquidityData.result.reserveA *(10**this.state.liquidityData.result.token1Decimals);
        const reserve_b = this.state.liquidityData.result.reserveB*(10**this.state.liquidityData.result.token2Decimals);

        if (this.state.liquidityData.result.totalSupply === 0){
            return Math.sqrt(((reserve_a * reserve_b)-MINIMUM_LIQUIDITY))*10**(-18);
        };

        return Math.min(valueA*this.state.liquidityData.result.totalSupply/reserve_a, valueB*this.state.liquidityData.result.totalSupply/reserve_b);
    }

    sharePoolCal(receiveTokens) {
        var total = receiveTokens + this.state.liquidityData.result.totalSupply;
        var perc = (receiveTokens / total) * 100;

        this.setState({poolShare: perc});
    }

    maxLimitEvet = (type) => {
        var inAmt = 0;
        var outAmt = 0;
        var params = {};

        if (type === "input") {
            inAmt = this.formatData(this.state.filteredInputBalance, 6);
            outAmt = this.formatData(this.state.filteredOutputBalance, 6);

            params = {
                inputVal: inAmt,
                outputVal: outAmt,
                token2: this.state.liquidityData ? this.state.liquidityData.result.token2price : 0,
                token1allowance: this.state.liquidityData ? this.state.liquidityData.result.token1allowance : 0,
                token2allowance: this.state.liquidityData ? this.state.liquidityData.result.token2allowance : 0
            }

            this.service.amtCalInputEvent(params).then(res => {
                this.setState(res);
            });
        } else {
            inAmt = this.formatData(this.state.filteredInputBalance, 6);
            outAmt = this.formatData(this.state.filteredOutputBalance, 6);

            params = {
                inputVal: inAmt,
                outputVal: outAmt,
                token1: this.state.liquidityData ? this.state.liquidityData.result.token1price : 0,
                token2allowance: this.state.liquidityData ? this.state.liquidityData.result.token2allowance : 0,
            }

            this.service.amtCalOutputEvent(params).then(res => {
                this.setState(res);
            });
        }
    }

    getLiquidityList() {
        var params = {
            address: this.service.address()
        };

        this.setState({ispairloading: true});

        this.service.getLiquidityList(params).then(res => {
            if (res && res.data && res.data.data) {
                this.setState({liquidityList: res.data.data, ispairloading: false});
            }
        });
    }

    filterLiquidity = (name, contractAddress, type) => {
        if (type === "input") {
            this.setState({ exfilteredTxtLiqtyInput: name, exfilteredTxtLiqtyInputContract: contractAddress, exfilteredTxtInput: name });

            this.showLiqInputCoin();

            if (contractAddress && this.state.exfilteredTxtLiqtyOutputContract) {
                this.checkLiquidity(contractAddress, this.state.exfilteredTxtLiqtyOutputContract);
            }
        } else {
            this.setState({ exfilteredTxtLiqtyOutput: name, exfilteredTxtLiqtyOutputContract: contractAddress, exfilteredTxtOutput: name });

            this.showLiqOutputCoin();

            if (this.state.exfilteredTxtLiqtyInputContract && contractAddress) {
                this.checkLiquidity(this.state.exfilteredTxtLiqtyInputContract, contractAddress);
            }
        }
    }

    showLiqInputCoin = () => {
        this.setState(prevState => ({
            exinputCoinShow: !prevState.exinputCoinShow
        }));
    }

    showLiqOutputCoin = () => {
        this.setState(prevState => ({
            exoutputCoinShow: !prevState.exoutputCoinShow
        }));
    }

    checkLiquidity = (input, output) => {
        if (this.service.address()) {
            var params = {
                address_input: input,
                address_output: output,
                from_address: this.service.address()
            };

            this.setState({isLiqLoading: true});

            this.service.checkLiquidity(params).then(res => {
                if (res.success) {
                    if (res.data && res.data.result) {
                        let isFirstL = (res.data.error) ? true : false;
                        this.setState({liquidityData: res.data, isFirstLiq: isFirstL, isLiqLoading: false});
                        this.service.setLiquidity(res.data.result);
                        this.showLiquidityView();
                    } else {
                        this.setState({liqAlertShow: true, isShowAddLiq: true, isLiqLoading: false});
                    }
                }
            });
        }
    }

    showLiquidityView = () => {
        var input = localStorage.getItem("balance") ? localStorage.getItem("balance") : 0;
        var output = 0;
        var exinput = 0;
        var exoutput = 0;

        if (this.state.tokensBalance) {
            var balanceData = this.state.tokensBalance;

            if (balanceData) {
                balanceData.forEach((value) => {
                    if (this.state.filteredTxtInput === value.slug) {
                        input = value.balance;
                    }

                    if (this.state.filteredTxtOutput === value.slug) {
                        output = value.balance;
                    }

                    if (this.state.exfilteredTxtInput === value.slug) {
                        exinput = value.balance;
                    }

                    if (this.state.exfilteredTxtOutput === value.slug) {
                        exoutput = value.balance;
                    }
                });
            }
        }

        this.setState({isEnableLiqView: true, filteredInputBalance: input, filteredOutputBalance: output, exfilteredInputBalance: exinput, exfilteredOutputBalance: exoutput});
    }

    tokenApproveConfirmed = (data) => {
        if (data.hash) {
            if (this.state.approveFilterType === "input") {
                toast.success(this.state.exfilteredTxtInput + " approved successfully");

                this.setState({exisShowInputAllowence: false});

            } else {
                toast.success(this.state.exfilteredTxtOutput + " approved successfully");

                this.setState({exisShowOutputAllowence: false});
            }
        } else {
            toast.error("Approve Cancelled");
        }
    }

    warningMessage = (data) => {
        toast.error(data);
    }

    render() {
        document.body.classList.add('home');

        return <div>
        <section className="homebannerbg">
            <div className="container sitebannercontent">
                <div className="row">
                    <div className="col-md-12">
                        <div className="text-center">
                            <h3 className="h3">Discover the power of Decentralized by creating, earning and trading on the dex platform</h3>
                        </div>

                        <div className="wlltfrm-circlebg">
                            <div className="wallet-frm">
                                <h4 className="h4">SWAP</h4>
                                <div className="siteformbox">
                                    {
                                        this.state.isShowInputAlert &&
                                        <div className="alert-org-color alert alert-danger"><i className="fa fa-info-circle"></i> Insufficient {this.state.filteredTxtInput} Balance </div>
                                    }
                                    <div className={`form-group cryptobg ${ this.state.isBalLoading ? 'common-loading pe-none' : '' } `}>
                                        <div className="input-group labelinput">
                                            <span className="input-group-text">
                                                <label className="labelbox">
                                                    From
                                                </label>
                                            </span>
                                            <span className="pull-right text-right">
                                                <span>Balance : { this.formatData(this.state.filteredInputBalance, 6) }</span>
                                            </span>
                                        </div>
                                        <div className="input-group">
                                            <input className="form-control form-control-lg" disabled={this.state.isBalLoading} value={ this.state.filteredTxtInputPercBal } onChange={this.amtCalInputEvent} placeholder={this.state.filteredTxtInputPercBal} />
                                            <span className="input-group-text">
                                                <div className="convertbox">
                                                    <a onClick={this.showInputCoin}>
                                                        { this.filteredImageLink(this.state.filteredTxtInput, 'input') }
                                                        <i><img src={droparrow} alt={droparrow} /></i>
                                                    </a>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="form-group text-center">
                                        { this.state.isBalLoading ?
                                            <ReactLoading type="bars" color="#ff00c7" height={'6%'} width={'6%'} className="flex flex-wrap content-center justify-center w-5 h-5 bg-blue w-5 ma2 h4 items-center justify-center flex flex-column flex-wrap mx-auto" />
                                            :
                                            <i><img src={swap}  alt={swap} /></i>
                                        }
                                    </div>

                                    <div className={`form-group cryptobg ${ this.state.isBalLoading ? 'common-loading pe-none' : '' } `}>
                                        <div className="input-group labelinput"> <span className="input-group-text">
                                                <label className="labelbox">
                                                    To
                                                </label>
                                            </span> <span><span>Balance : { this.formatData(this.state.filteredOutputBalance, 6) }</span></span>
                                        </div>
                                        <div className="input-group">
                                            <input className="form-control form-control-lg" disabled={this.state.isBalLoading} value={ this.state.filteredTxtOutputPercBal } onChange={this.amtCalOutputEvent} placeholder={this.state.filteredTxtOutputPercBal} />
                                            <span className="input-group-text">
                                                <div className="convertbox">
                                                    <a onClick={this.showOutputCoin}>
                                                        { this.filteredImageLink(this.state.filteredTxtOutput, 'output') }
                                                        <i><img src={droparrow} alt={droparrow} /></i>
                                                    </a>
                                                </div>
                                            </span>
                                        </div>
                                    </div>

                                    {
                                        this.showConfirmSection() &&

                                        <div className="form-group text-center btncenterbox">
                                            { (this.state.isShowInputAllowence) ?
                                                <button type="button" className="btn sitebtn btn-block" onClick={ () => this.spenderApproveEvent() }>Approve { this.state.filteredTxtInput } </button>
                                                :
                                                <>
                                                    <button type="button" className="btn border-btn" onClick={ ()=> this.swapConfirmationCancelEvent() }>Cancel</button>
                                                    <button type="button" className="btn sitebtn" id="confirmwallet" onClick={ () => this.swapConfirmationEvent() }>Confirm</button>
                                                </>
                                            }
                                        </div>
                                    }

                                    <div className="form-group c-btn">
                                        { !this.service.address() && <ConnectWallet name="menu"></ConnectWallet> }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* <section className='pricelistbg'>
            <div className='container'>
                <div className="priceloder">
                    <ul className="charts" id="myul">
                        {   (this.state.pairs.length > 0) &&

                            this.state.pairs.map((data, i) => (
                                <li key={i}>
                                    <div className="live-table">
                                        <div className="livecoinig">
                                            <img src={data.coinone.toLocaleLowerCase()} className="f-icon" alt={data.coinone.toLocaleUpperCase()} />
                                        </div>
                                        <div>
                                            <h2 className="h2">{data.name}</h2>
                                            <h4 className="h4 price_change_BTCUSDT">{data.coinone}/{data.cointwo}</h4>
                                        </div>
                                        <div>
                                            <h2 className="h2"><span className="last_price_BTCUSDT"></span>$ 0</h2>
                                            <h4 className="h4">0%</h4>
                                        </div>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </section> */}

        <section className="features">
            <div className="container">
                <h1 className="heading-title"><span className="t-pink">Welcome to our website,</span> where you can trade cryptocurrencies directly with other users without the need for centralized intermediaries.</h1>
                <div className="f-flex">
                    <div className="f-box">
                        <div><span className="ficonbg"><img src={ficon1} className="f-icon" /></span></div>
                        <div><h5 className="h5">Start in Seconds</h5>
                        <p className="content">Start trading cryptocurrencies in seconds with a simple, secure and easy to set up account.</p></div>
                    </div>
                    <div className="f-box">
                    <div><span className="ficonbg"><img src={ficon2} className="f-icon" /></span></div>
                    <div><h5 className="h5">Secured Transactions</h5>
                        <p className="content">Secure transactions are essential in cryptocurrencies; We use a blockchain-based peer-to-peer (P2P) network.</p></div>
                    </div>
                    <div className="f-box">
                    <div> <span className="ficonbg"><img src={ficon3} className="f-icon" /></span></div>
                    <div>   <h5 className="h5">Earn Passive Income</h5>
                        <p className="content">Earn passive income with staking, farming and other cryptocurrency investment options available in our DEXs.</p></div>
                    </div>
                </div>
            </div>
        </section>
        <section className="how-bg">
            <div className="container">
                <h1 className="heading-title text-center">How It Works</h1>
                <div className="h-content d-flex">
                    <div className="h-box">
                        <div> <img src={hicon1} /> </div>
                        <div>
                            <h6 className="h6">1. Connect Wallet</h6>
                            <p className="content">With just a few clicks, WalletConnect can connect your mobile wallet. </p>
                        </div>
                    </div>
                    <div className="h-box">
                        <div> <img src={hicon2} /> </div>
                        <div>
                            <h6 className="h6">2. Input A Token Pair and Amount</h6>
                            <p className="content">With just a few clicks, WalletConnect can connect your mobile wallet. </p>
                        </div>
                    </div>
                </div>
                <div className="h-content d-flex">
                    <div className="h-box">
                        <div> <img src={hicon3} /> </div>
                        <div>
                            <h6 className="h6">3. Swap Tokens</h6>
                            <p className="content">With just a few clicks, WalletConnect can connect your mobile wallet. </p>
                        </div>
                    </div>
                    <div className="h-box">
                        <div> <img src={hicon4} /> </div>
                        <div>
                            <h6 className="h6">4. Get Your Tokens</h6>
                            <p className="content">With just a few clicks, WalletConnect can connect your mobile wallet. </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="viewbg">   
            <div className="container">
            <div className="row align-items-center">
                    <div className="col-lg-3 col-md-5 col-12">
                    <div className="v-flex">
                    <div className="v-box">
                        <h4 className="h4">$2569</h4>
                        <h6 className="h6">Trade Volume</h6>
                    </div>
                    <div className="v-box">
                        <h4 className="h4">1.5M+</h4>
                        <h6 className="h6">All Time Users</h6>
                    </div>
                    <div className="v-box">
                        <h4 className="h4">$6485</h4>
                        <h6 className="h6">Total Liquidity</h6>
                    </div>
                    <div className="v-box">
                        <h4 className="h4">8,245+</h4>
                        <h6 className="h6">Community Delegates</h6>
                    </div>
                </div>
                </div>
                    <div className="col-lg-9 col-md-7 col-12">
                        <div className="aboutboxbg">
                    <div><h1 className="heading-title">About Us</h1>
                        <p className="content">Diam vel quam elementum pulvinar. Dictum non consectetur a  at lectus urna. Tempus imperdiet nulla malesuada pellentesque elit. Semper auctor neque vitae tempus quam pellentesque nec nam aliquam. Faucibus in ornare quam viverra. Vel pharetra vel turpis nunc eget lorem dolor. Ac placerat vestibulum lectus mauris ultrices eros in. Nunc sed augue lacus viverra vitae.</p></div>
                </div>
                    </div>
                </div>
            </div>  
        </section>

        <section className="mobileappbg">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                    <h1 className="heading-title text-start">Download Our App</h1>
                    <p className="content">Arcu felis bibendum ut tristique et egestas. Adipiscing elit duis tristique sollicitudin nibh sit amet aliquam faucibus commodo.</p>
                    <div className="appbtn mt-3">
                        <a href="#" className="me-2"><img src={appicon1} /></a>
                        <a href="#"><img src={appicon2} /></a>
                    </div>
                    </div>
                    <div className="col-md-6 text-center mobileappicon">
                        <img src={mobileapp} />
                    </div>
                </div>
            </div>
        </section>

        <section className="faq-bg">
            <div className="container">
                <h1 className="heading-title mb-3">Frequently Asked Questions</h1>
                <div className="wrapper" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <h4> What Is A Decentralized Exchange?</h4>
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <p className="content"> Maecenas consectetur, leo eu lobortis placerat, diam nisi fringilla arcu, a
                                    feugiat tortor dui
                                    vel urna. Fusce erat erat, luctus in mi at, placerat commodo lectus. Ut tincidunt turpis
                                    varius
                                    augue varius mollis. Sed dapibus enim purus, id tempus ante tincidunt nec.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item shadow-none">
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <h4> How Does Our DEX Aggregator Work?</h4>
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <p className="content"> Maecenas consectetur, leo eu lobortis placerat, diam nisi fringilla arcu, a
                                    feugiat tortor dui
                                    vel urna. Fusce erat erat, luctus in mi at, placerat commodo lectus. Ut tincidunt turpis
                                    varius
                                    augue varius mollis. Sed dapibus enim purus, id tempus ante tincidunt nec.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item shadow-none">
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <h4> Why should i Trust Demo Dex Exchange?</h4>
                            </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <p className="content"> Maecenas consectetur, leo eu lobortis placerat, diam nisi fringilla arcu, a
                                    feugiat tortor dui
                                    vel urna. Fusce erat erat, luctus in mi at, placerat commodo lectus. Ut tincidunt turpis
                                    varius
                                    augue varius mollis. Sed dapibus enim purus, id tempus ante tincidunt nec.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item shadow-none">
                        <h2 className="accordion-header" id="headingFour">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <h4> Why Is It Safe To Use Demo Dex Exchange?</h4>
                            </button>
                        </h2>
                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <p className="content"> Maecenas consectetur, leo eu lobortis placerat, diam nisi fringilla arcu, a
                                    feugiat tortor dui
                                    vel urna. Fusce erat erat, luctus in mi at, placerat commodo lectus. Ut tincidunt turpis
                                    varius
                                    augue varius mollis. Sed dapibus enim purus, id tempus ante tincidunt nec.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item shadow-none">
                        <h2 className="accordion-header" id="headingFive">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                                <h4> What are trading fees?</h4>
                            </button>
                        </h2>
                        <div id="collapseFive" className="accordion-collapse collapse " aria-labelledby="headingFive"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <p className="content"> Maecenas consectetur, leo eu lobortis placerat, diam nisi fringilla arcu, a
                                    feugiat tortor dui
                                    vel urna. Fusce erat erat, luctus in mi at, placerat commodo lectus. Ut tincidunt turpis
                                    varius
                                    augue varius mollis. Sed dapibus enim purus, id tempus ante tincidunt nec.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="infoadsbanner">
            <div className="container">
                <div className="infoadsbg">
                <div className="row align-items-center">
                    <div className="col-lg-8 col-md-7">
                        <h1 className="heading-title text-start pb-1">Decentralization is the future!</h1>
                        <p className="content">Tempus imperdiet nulla malesuada pellentesque semper auctor.</p>
                    </div>
                    <div className="col-lg-4 col-md-5 text-end">
                        <a href="#" className="btn sitebtn whitebtn">Connect Wallet</a>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        

        <Modal
            show={this.state.inputCoinShow}
            onHide={() => this.showInputCoin}
            dialogClassName="modalbgt cryptomodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">Select Coin </Modal.Title>
                <button className="close" onClick={this.showInputCoin}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <form className="siteformbox">
                    <TokenComponent data={this.state.tokensBalance} name="input" clickHandler={this.filteredItemEvent} filterTxtName={this.state.filteredTxtOutput}></TokenComponent>
                </form>
            </Modal.Body>
        </Modal>

        <Modal
            show={this.state.outputCoinShow}
            onHide={() => this.showOutputCoin}
            dialogClassName="modalbgt cryptomodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">Select Coin</Modal.Title>
                <button className="close" onClick={this.showOutputCoin}>&times;</button>
            </Modal.Header>
            <Modal.Body>
                <form className="siteformbox">
                    <TokenComponent
                        data={this.state.tokensBalance}
                        name="output"
                        clickHandler={this.filteredItemEvent}
                        filterTxtName={this.state.filteredTxtInput}
                    ></TokenComponent>
                </form>
            </Modal.Body>
        </Modal>

        <ToastContainer autoClose={5000} />
        
        <SwapUtil
                ref={this.swapUtilRef}
                clickHandlerSpenderApprove={this.spenderApproveConfirmed}
                clickHandlerSwapConfirmed={this.swapConfirmed}
                clickHandlerWarningError={this.warningMessage}
        ></SwapUtil>
        </div>
    }
}

export default Home;
