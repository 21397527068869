import React, { useEffect, useState, useCallback, forwardRef, useImperativeHandle, useMemo } from "react";
import { useSendTransaction, usePrepareSendTransaction, useWaitForTransaction } from 'wagmi';
import { useDebounce } from 'use-debounce';

import { ethers, utils, BigNumber } from "ethers";
import { param } from "jquery";

const ExchangeUtil = forwardRef((props, ref) => {
    const [ removeTo, setRemoveTo ] = useState('');
    const [ removeData, setRemoveData ] = useState('');
    const [ removeValue, setRemoveValue ] = useState('');

    const [debouncedTo] = useDebounce(removeTo, 500);
    const [debouncedData] = useDebounce(removeData, 500);
    const [debouncedValue] = useDebounce(removeValue, 500);

    const [ exchangeClickShow, setExchangeClickedShow] = useState(false);
    const [ exchangeClicked, setExchangeClicked] = useState(false);
    const [ exchangeConfig, setExchangeConfig] = useState(false);

    const { config } = usePrepareSendTransaction({
        request: { data: debouncedData, to: debouncedTo, value: debouncedValue},
        onError(data) {
            if (exchangeClickShow) {
                props.clickHandlerExchangeError(data.code);
            }
        },
        onSettled(data) {
            setExchangeConfig(true);
        }
    });

    const { data, isLoading, isError, isSuccess, sendTransactionAsync } = useSendTransaction(config);

    const exchangeAllow = useMemo(() => (sendTransactionAsync) ? true : false, [sendTransactionAsync]);
    const exchangeDone = useMemo(() => isSuccess? true : false , [isSuccess]);

    const [ exchangeHash, setExchangeHash] = useState('');

    const {data: exchangeTransData, isSuccess: exchangeTransSuccess, isError: exchangeTransError} = useWaitForTransaction({
        hash: exchangeHash,
        onSuccess(data) {
            if (data) {
                props.clickHandlerLiquidity({hash: data.transactionHash});
            }
        },
        onError(data) {
            let obj = { hash : ""};
            props.clickHandlerLiquidity(obj);
        }
    });

    useEffect(() => {
        if (exchangeAllow) {
            sendTransactionAsync();
        }
    }, [exchangeAllow, exchangeClicked]);

    useEffect(() => {
        if (exchangeDone) {
            setRemoveTo('');
            setRemoveData('');
            setRemoveValue('');
            setExchangeClickedShow(false);

            setExchangeHash(data.hash);
        }

    }, [exchangeDone]);

    useEffect(() => {
        if (isError) {
            setRemoveTo('');
            setRemoveData('');
            setRemoveValue('');
            setExchangeClickedShow(false);

            let obj = { hash : ""};
            props.clickHandlerLiquidity(obj);
        }
    }, [isError]);

    const [ removeToContract, setRemoveToContract ] = useState('');
    const [ removeTokenApproveData, setRemoveTokenApproveData ] = useState('');

    const [debouncedToContract] = useDebounce(removeToContract, 500);
    const [debouncedTokenApproveData] = useDebounce(removeTokenApproveData, 500);

    const [ tokenApproveClickShow, setTokenApproveClickedShow] = useState(false);
    const [ tokenApproveClicked, setTokenApproveClicked] = useState(false);

    const { config: tokenApproveConfig } = usePrepareSendTransaction({
        request: { data: debouncedTokenApproveData, to: debouncedToContract, value: '0'},
        onError(data) {
            if (tokenApproveClickShow) {
                props.clickHandlerExchangeError(data.code);
            }
        }
    });

    const { data: tokenApproveData, isLoading: tokenApproveLoading, isError: tokenApproveError, isSuccess: tokenApproveSuccess, sendTransactionAsync: tokenApproveTransactionAsync } = useSendTransaction(tokenApproveConfig);

    const tokenApproveAllow = useMemo(() => tokenApproveTransactionAsync? true : false , [tokenApproveTransactionAsync]);
    const tokenApproveDone = useMemo(() => tokenApproveSuccess? true : false , [tokenApproveSuccess]);

    const [ tokenApproveHash, setTokenApproveHash] = useState('');

    const {data: tokenApproveTransData, isSuccess: tokenApproveTransSuccess, isError: tokenApproveTransError} = useWaitForTransaction({
        hash: tokenApproveHash,
        onSuccess(data) {
            if (data) {
                props.clickHandlerRemoveTokenApprove({hash: data.transactionHash});
            }
        },
        onError(data) {
            let obj = { hash : ""};
            props.clickHandlerRemoveTokenApprove(obj);
        }
    });

    useEffect(() => {
        if (tokenApproveAllow) {
            tokenApproveTransactionAsync();
        }
    }, [tokenApproveAllow, tokenApproveClicked]);

    useEffect(() => {
        if (tokenApproveDone) {
            setRemoveToContract('');
            setRemoveTokenApproveData('');
            setTokenApproveClickedShow(false);

            setTokenApproveHash(tokenApproveData.hash);
        }

    }, [tokenApproveDone]);

    useEffect(() => {
        if (tokenApproveError) {
            setRemoveToContract('');
            setRemoveTokenApproveData('');
            setTokenApproveClickedShow(false);

            let obj = { hash : ""};
            props.clickHandlerRemoveTokenApprove(obj);
        }
    }, [tokenApproveError]);

    const [ newTokenApproveToContract, setTokenApproveToContract ] = useState('');
    const [ newTokenApproveData, setTokenApproveData ] = useState('');

    const [debouncedTokenApproveToContract] = useDebounce(newTokenApproveToContract, 500);
    const [debouncedNTokenApproveData] = useDebounce(newTokenApproveData, 500);

    const [ newTokenApproveClickShow, setNewTokenApproveClickedShow] = useState(false);
    const [ newTokenApproveClicked, setNewTokenApproveClicked] = useState(false);

    const { config: newTokenApproveConfig } = usePrepareSendTransaction({
        request: { data: debouncedNTokenApproveData, to: debouncedTokenApproveToContract, value: '0'},
        onError(data) {
            if (newTokenApproveClickShow) {
                props.clickHandlerExchangeError(data.code);
            }
        }
    });

    const { data: newTokenData, isLoading: newTokenApproveLoading, isError: newTokenApproveError, isSuccess: newTokenApproveSuccess, sendTransactionAsync: newTokenApproveTransactionAsync } = useSendTransaction(newTokenApproveConfig);

    const newTokenApproveAllow = useMemo(() => newTokenApproveTransactionAsync? true : false , [newTokenApproveTransactionAsync]);
    const newTokenApproveDone = useMemo(() => newTokenApproveSuccess? true : false , [newTokenApproveSuccess]);

    const [ newTokenApproveHash, setNewTokenApproveHash] = useState('');

    const {data: newTokenApproveTransData, isSuccess: newTokenApproveTransSuccess, isError: newTokenApproveTransError} = useWaitForTransaction({
        hash: newTokenApproveHash,
        onSuccess(data) {
            if (data) {
                props.clickHandlerTokenApprove({hash: data.transactionHash});
            }
        },
        onError(data) {
            let obj = { hash : ""};
            props.clickHandlerTokenApprove(obj);
        }
    });

    useEffect(() => {
        if (newTokenApproveAllow) {
            newTokenApproveTransactionAsync();
        }
    }, [newTokenApproveAllow, newTokenApproveClicked]);

    useEffect(() => {
        if (newTokenApproveDone) {
            setTokenApproveToContract('');
            setTokenApproveData('')
            setNewTokenApproveClickedShow(false);

            setNewTokenApproveHash(newTokenData.hash);
        }

    }, [newTokenApproveDone]);

    useEffect(() => {
        if (newTokenApproveError) {
            setTokenApproveToContract('');
            setTokenApproveData('')
            setNewTokenApproveClickedShow(false);

            let obj = { hash : ""};
            props.clickHandlerTokenApprove(obj);
        }
    }, [newTokenApproveError]);

    const [ liquidityTo, setLiquidityTo ] = useState('');
    const [ liquidityData, setLiquidityData ] = useState('');
    const [ liquidityValue, setLiquidityValue ] = useState('');

    const [debouncedLiquidityTo] = useDebounce(liquidityTo, 500);
    const [debouncedLiquidityData] = useDebounce(liquidityData, 500);
    const [debouncedLiquidityValue] = useDebounce(liquidityValue, 500);

    const [ liquidityClickShow, setLiquidityClickedShow] = useState(false);
    const [ liquidityClicked, setLiquidityClicked] = useState(false);

    const { config: liquidityConfig } = usePrepareSendTransaction({
        request: { data: debouncedLiquidityData, to: debouncedLiquidityTo, value: debouncedLiquidityValue},
        onError(data) {
            if (liquidityClickShow) {
                props.clickHandlerExchangeError(data.code);
            }
        }
    });

    const { data: liquidity, isLoading: liquidityLoading, isError: liquidityError, isSuccess: liquiditySuccess, sendTransactionAsync: liquidityTransactionAsync, reset: liquidityReset } = useSendTransaction(liquidityConfig);

    const liquidityAllow = useMemo(() => liquidityTransactionAsync? true : false , [liquidityTransactionAsync]);
    const liquidityDone = useMemo(() => liquiditySuccess? true : false , [liquiditySuccess]);

    const [ liquidityHash, setLiquidityHash] = useState('');

    const {data: liquidityTransData, isSuccess: liquidityTransSuccess, isError: liquidityTransError} = useWaitForTransaction({
        hash: liquidityHash,
        onSuccess(data) {
            if (data) {
                props.clickHandlerAddLiquidity({hash: data.transactionHash});
            }
        },
        onError(data) {
            let obj = { hash : ""};
            props.clickHandlerAddLiquidity(obj);
        }
    });

    useEffect(() => {
        if (liquidityAllow) {
            liquidityTransactionAsync();
        }
    }, [liquidityAllow, liquidityClicked]);

    useEffect(() => {
        if (liquidityDone) {
            setLiquidityTo('');
            setLiquidityData('');
            setLiquidityValue('');
            setLiquidityClickedShow(false);

            setLiquidityHash(liquidity.hash);
        }

    }, [liquidityDone]);

    useEffect(() => {
        if (liquidityError) {
            setLiquidityTo('');
            setLiquidityData('');
            setLiquidityValue('');
            setLiquidityClickedShow(false);

            let obj = { hash : ""};
            props.clickHandlerAddLiquidity(obj);
        }
    }, [liquidityError]);

    useImperativeHandle(ref, () => ({
        removeLiqEvt(params) {
            setRemoveTo(params.to);
            setRemoveData(params.data);
            setRemoveValue(params.value);

            setExchangeClickedShow(true);
            setExchangeClicked(!exchangeClicked);
        },
        removeTokenApprove(params) {
            setRemoveToContract(params.contract);
            setRemoveTokenApproveData(params.data.data);

            setTokenApproveClickedShow(true);
            setTokenApproveClicked(!tokenApproveClicked);           
        },
        tokenApprove(params) {
            setTokenApproveToContract(params.contract);
            setTokenApproveData(params.data.data);

            setNewTokenApproveClickedShow(true);
            setNewTokenApproveClicked(!newTokenApproveClicked);
        },
        addLiquidity(params) {
            setLiquidityTo(params.to);
            setLiquidityData(params.data);
            setLiquidityValue(params.value);

            setLiquidityClickedShow(true);
            setLiquidityClicked(!liquidityClicked);
        }
    }));
});

export default ExchangeUtil;