import React, { useEffect, useState, useCallback, forwardRef, useImperativeHandle, useMemo } from "react";
import eventBus from "../events/EventBus";
import { usePrepareContractWrite, useContractWrite, ChainDoesNotSupportMulticallError, erc20ABI, useWaitForTransaction } from 'wagmi';
import { useDebounce } from 'use-debounce';

import { ethers, utils } from "ethers";

import StakeABI from "../ABI/farm.json";

const StakeUtil = forwardRef((props, ref) => {
    /* Deposit Start */
    const [ amount, setAmount ] = useState(0);
    const [ depositposition, setDepositPosition ] = useState(0);

    const [debouncedAmount] = useDebounce(amount, 500);
    const [debouncedDepositPosition] = useDebounce(depositposition, 500);

    const [ depositClickedShow, setDepositClickedShow] = useState(false);
    const [ depositClicked, setDepositClicked] = useState(false);

    const { config: stakeConfig } = usePrepareContractWrite({
        address: process.env.REACT_APP_FARM_ADDRESS,
        abi: StakeABI,
        functionName: 'deposit',
        args: [debouncedDepositPosition, ethers.utils.parseEther(amount.toString())],
        onError(data) {
            if (depositClickedShow) {
                props.clickHandlerDepositError(data.code);
            }
        }
    });

    const { data: stakeData, isError: isStakeError, isSuccess: isStakeSuccess, writeAsync: stakeWriteAsync } = useContractWrite(stakeConfig);

    const depositAllow = useMemo(() => stakeWriteAsync? true : false , [stakeWriteAsync, amount]);
    const depositDone = useMemo(() => isStakeSuccess? true : false , [isStakeSuccess]);

    const [ depositHash, setDepositHash] = useState('');

    const {data: depositTransData, isSuccess: depositTransSuccess, isError: depositTransError} = useWaitForTransaction({
        hash: depositHash,
        onSuccess(data) {
            if (data) {
                setDepositClickedShow(false);
                props.clickHandler({hash: data.transactionHash});
            }
        },
        onError(data) {
            let obj = { hash : ""};
            props.clickHandler(obj);
        }
    });

    useEffect(() => {
        if (depositAllow && depositClickedShow) {
            stakeWriteAsync();
        }
    }, [depositAllow, depositClicked]);

    useEffect(() => {
        if (depositDone) {
            setAmount(0);
            setDepositClickedShow(false);

            setDepositHash(stakeData.hash);
        }

    }, [depositDone]);

    useEffect(() => {
        if (isStakeError) {
            setAmount(0);
            setDepositClickedShow(false);

            let obj = { hash : ""};
            props.clickHandler(obj);
        }
    }, [isStakeError]);

    /* Deposit End */

    /* Withdraw Start */
    const [ withdrawAmount, setWithdrawAmount ] = useState(0);
    const [ withdrawPosition, setWithdrawPosition ] = useState(0);
    const [debouncedWithdrawAmount] = useDebounce(withdrawAmount, 0);
    const [debouncedWithdrawPosition] = useDebounce(withdrawPosition, 0);

    const [ withdrawClickedShow, setWithdrawClickedShow] = useState(false);
    const [ withdrawClicked, setWithdrawClicked] = useState(false);

    const { config: withdrawConfig } = usePrepareContractWrite({
        address: process.env.REACT_APP_FARM_ADDRESS,
        abi: StakeABI,
        functionName: 'withdraw',
        args: [debouncedWithdrawPosition, ethers.utils.parseEther(withdrawAmount.toString())]
    });

    const { data: withdrawData, isError: isWithdrawError, isSuccess: isWithdrawSuccess, writeAsync: withdrawWriteAsync } = useContractWrite(withdrawConfig);

    const withdrawAllow = useMemo(() => withdrawWriteAsync? true : false , [withdrawWriteAsync, withdrawAmount]);
    const withdrawDone = useMemo(() => isWithdrawSuccess? true : false , [isWithdrawSuccess]);

    const [ withdrawHash, setWithdrawHash] = useState('');

    const {data: withdrawTransData, isSuccess: withdrawTransSuccess, isError: withdrawTransError} = useWaitForTransaction({
        hash: withdrawHash,
        onSuccess(data) {
            if (data) {
                props.clickHandlerWithdraw({hash: data.transactionHash});
            }
        },
        onError(data) {
            let obj = { hash : ""};
            props.clickHandlerWithdraw(obj);
        }
    });

    useEffect(() => {
        if (withdrawAllow && withdrawClickedShow) {
            withdrawWriteAsync();
        }
    }, [withdrawAllow, withdrawClicked]);

    useEffect(() => {
        if (withdrawDone) {
            setWithdrawAmount(0);
            setWithdrawClickedShow(false);

            setWithdrawHash(withdrawData.hash);
        }

    }, [withdrawDone]);

    useEffect(() => {
        if (isWithdrawError) {
            setWithdrawAmount(0);
            setWithdrawClickedShow(false);

            let obj = { hash : ""};
            props.clickHandlerWithdraw(obj);
        }
    }, [isWithdrawError]);

    /* Withdraw End */

    /* UnStake Start */
    const [ unStakeAmount, setUnStakeAmount ] = useState(0);
    const [ unStakePosition, setUnStakePosition ] = useState(0);
    const [debouncedUnStakeAmount] = useDebounce(unStakeAmount, 500);
    const [debouncedUnStakePosition] = useDebounce(unStakePosition, 500);

    const [ unStakedClickedShow, setUnStakeClickedShow] = useState(false);
    const [ unStakedClicked, setUnStakeClicked] = useState(false);

    const { config: unStakeConfig } = usePrepareContractWrite({
        address: process.env.REACT_APP_FARM_ADDRESS,
        abi: StakeABI,
        functionName: 'emergencyWithdraw',
        args: [unStakePosition]
    });

    const { data: unStakeData, isError: isUnStakeError, isSuccess: isUnStakeSuccess, writeAsync: unStakeWriteAsync } = useContractWrite(unStakeConfig);

    const unStakeAllow = useMemo(() => unStakeWriteAsync? true : false , [unStakeAmount]);
    const unStakeDone = useMemo(() => isUnStakeSuccess? true : false , [isUnStakeSuccess]);

    const [ unStakeHash, setUnStakeHash] = useState('');

    const {data: unStakeTransData, isSuccess: unStakeTransSuccess, isError: unStakeTransError} = useWaitForTransaction({
        hash: unStakeHash,
        onSuccess(data) {
            if (data) {
                props.clickHandlerUnStake({hash: data.transactionHash});
            }
        },
        onError(data) {
            let obj = { hash : ""};
            props.clickHandlerUnStake(obj);
        }
    });

    useEffect(() => {
        if (unStakeAllow && unStakedClickedShow) {
            unStakeWriteAsync();
        }
    }, [unStakeAllow, unStakedClicked]);

    useEffect(() => {
        if (unStakeDone) {
            setUnStakeAmount(0);
            setUnStakeClickedShow(false);
            setUnStakeHash(unStakeData.hash);
        }

    }, [unStakeDone]);

    useEffect(() => {
        if (isUnStakeError) {
            setUnStakeAmount(0);
            setUnStakeClickedShow(false);

            let obj = { hash : ""};
            props.clickHandlerUnStake(obj);
        }
    }, [isUnStakeError]);

    /* UnStake End */

    /* Stake Approve Start */
    const [ stakeApproveContract, setStakeApproveContract ] = useState('');
    const [ stakeApproveAmt, setStakeApproveAmt ] = useState(0);

    const [debouncedStakeApproveContract] = useDebounce(stakeApproveContract, 500);
    const [debouncedStakeApproveAmt] = useDebounce(stakeApproveAmt, 500);

    const [ stakeApproveClickedShow, setStakeApproveClickedShow] = useState(false);
    const [ stakeApproveClicked, setStakeApproveClicked] = useState(false);

    const { config: stakeApproveConfig } = usePrepareContractWrite({
        address: debouncedStakeApproveContract,
        abi: erc20ABI,
        functionName: 'approve',
        args: [process.env.REACT_APP_FARM_ADDRESS, ethers.utils.parseEther(debouncedStakeApproveAmt.toString())]
    });

    const { data: stakeApproveData, isError: isStakeApproveError, isSuccess: isStakeApproveSuccess, writeAsync: stakeApproveWriteAsync } = useContractWrite(stakeApproveConfig);

    const stakeApproveAllow = useMemo(() => stakeApproveWriteAsync? true : false , [stakeApproveWriteAsync]);
    const stakeApproveDone = useMemo(() => isStakeApproveSuccess? true : false , [isStakeApproveSuccess]);

    const [ stakeApproveHash, setStakeApproveHash] = useState('');

    const {data: stakeApproveTransData, isSuccess: stakeApproveTransSuccess, isError: stakeApproveTransError} = useWaitForTransaction({
        hash: stakeApproveHash,
        onSuccess(data) {
            if (data) {
                setStakeApproveClickedShow(false);
                props.clickHandlerStakeApprove({hash: data.transactionHash});
            }
        },
        onError(data) {
            setStakeApproveClickedShow(false);

            let obj = { hash : ""};
            props.clickHandlerStakeApprove(obj);
        }
    });

    useEffect(() => {
        if (stakeApproveAllow && stakeApproveClickedShow) {
            stakeApproveWriteAsync();
        }
    }, [stakeApproveAllow]);

    useEffect(() => {
        if (stakeApproveDone) {
            setStakeApproveContract('');
            setStakeApproveAmt(0);

            setStakeApproveHash(stakeApproveData.hash);
        }

    }, [stakeApproveDone]);

    useEffect(() => {
        if (isStakeApproveError) {
            setStakeApproveContract('');
            setStakeApproveAmt(0);
            setStakeApproveClickedShow(false);

            let obj = { hash : ""};
            props.clickHandlerStakeApprove(obj);
        }
    }, [isStakeApproveError]);

    /* Stake Approve End */

    useImperativeHandle(ref, () => ({
        confirmEvt(params) {
            setAmount(params.amount);
            setDepositClickedShow(true);
            setDepositClicked(!depositClicked);
            setDepositPosition(params.position);
        },
        confirmWithdrawEvt(params) {
            setWithdrawAmount(params.amount);
            setWithdrawClickedShow(true);
            setWithdrawClicked(!withdrawClicked);
            setWithdrawPosition(params.position);
        },
        confirmUnStakeEvt(params) {
            setUnStakeAmount(params.amount);

            setUnStakeClickedShow(true);
            setUnStakeClicked(!unStakedClicked);
            setUnStakePosition(params.position);
        },
        stakeApproveEvt(params) {
            setStakeApproveContract(params.contractaddress);
            setStakeApproveAmt(params.amount);

            setStakeApproveClickedShow(true);
            setStakeApproveClicked(!stakeApproveClicked);
        }
    }));
});

export default StakeUtil;