class BaseService {
	// apiURL = "http://localhost/projects/office-demo/api/public/api/";   //Local
	apiURL = "https://new.demozab.com/demoswap/api/public/api/";   //Demo

	requestGETHeader() {
		const options = {
			method: 'GET',
			mode: 'cors'
		};

		return options;
	}

	requestPOSTHeader(params) {
		const options = {
			method: 'POST',
			mode: 'cors',
			body: JSON.stringify(params)
		};

		return options;
	}

	address() {
		return (localStorage.getItem("address") && localStorage.getItem("address") != null) ? localStorage.getItem("address") : "";
	}

	balance() {
		return localStorage.getItem("balance") ? localStorage.getItem("balance") : 0;
	}

	isPay() {
		return localStorage.getItem("pay") ? localStorage.getItem("pay") : false;
	}

	apiurl() {
		return this.apiURL;
	}

	async getTokens() {
		return fetch(this.apiURL + 'tokens', this.requestGETHeader()).then(this.handleResponse);
	}

	async getAllTokenBalance(params) {
		return fetch(this.apiURL + 'tokens-balance', this.requestPOSTHeader(params)).then(this.handleResponse);
	}

	async getTokensPrice(params) {
		return fetch(this.apiURL + 'token-price', this.requestPOSTHeader(params)).then(this.handleResponse);
	}

	async addLiquidity(params) {
		return fetch(this.apiURL + 'getSupply', this.requestPOSTHeader(params)).then(this.handleResponse);
	}

	async checkLiquidity(params) {
		return fetch(this.apiURL + 'check-liquidity', this.requestPOSTHeader(params)).then(this.handleResponse);
	}

	async approveToken(params) {
		return fetch(this.apiURL + 'approvetoken', this.requestPOSTHeader(params)).then(this.handleResponse);
	}

	async removeApproveToken(params) {
		return fetch(this.apiURL + 'removeapprovetoken', this.requestPOSTHeader(params)).then(this.handleResponse);
	}

	async swapConfirmation(params) {
		return fetch(this.apiURL + 'swap-confirmation', this.requestPOSTHeader(params)).then(this.handleResponse);
	}

	async getTokensAllowence(params) {
		return fetch(this.apiURL + 'tokens-allowence', this.requestPOSTHeader(params)).then(this.handleResponse);
	}

	async spenderApprove(params) {
		return fetch(this.apiURL + 'swap-approve', this.requestPOSTHeader(params)).then(this.handleResponse);
	}

	async getLiquidityList(params) {
		return fetch(this.apiURL + 'liquidity-list', this.requestPOSTHeader(params)).then(this.handleResponse);
	}

	async getLiquidityPairData(params) {
		return fetch(this.apiURL + 'getLiquidityListPair', this.requestPOSTHeader(params)).then(this.handleResponse);
	}

	async removeLiquidityPair(params) {
		return fetch(this.apiURL + 'removeLiquidityPair', this.requestPOSTHeader(params)).then(this.handleResponse);
	}

	async tokenListingRequest(params) {
		return fetch(this.apiURL + 'token-listing', this.requestPOSTHeader(params)).then(this.handleResponse);
	}

	async getPrivacy() {
		return fetch(this.apiURL + 'privacy', this.requestGETHeader()).then(this.handleResponse);
	}

	async getTerms() {
		return fetch(this.apiURL + 'terms', this.requestGETHeader()).then(this.handleResponse);
	}

	async getFaq() {
		return fetch(this.apiURL + 'faqs', this.requestGETHeader()).then(this.handleResponse);
	}

	async getHomeCms() {
		return fetch(this.apiURL + 'home-cms', this.requestGETHeader()).then(this.handleResponse);
	}

	async getHomeFooter() {
		return fetch(this.apiURL + 'home-footer', this.requestGETHeader()).then(this.handleResponse);
	}

	async getPaymentStatus(params) {
		return fetch(this.apiURL + 'checkpay', this.requestPOSTHeader(params)).then(this.handleResponse);
	}

	async paymentConfirmation(params) {
		return fetch(this.apiURL + 'payment-confirmation', this.requestPOSTHeader(params)).then(this.handleResponse);
	}

	async updatePayment(params) {
		return fetch(this.apiURL + 'update-payment', this.requestPOSTHeader(params)).then(this.handleResponse);
	}

	async getTransactionSpeed() {
		return fetch(this.apiURL + 'transaction-speed', this.requestGETHeader()).then(this.handleResponse);
	}

	async getFarms(params) {
		return fetch(this.apiURL + 'farms', this.requestPOSTHeader(params)).then(this.handleResponse);
	}

	async getPools(params) {
		return fetch(this.apiURL + 'pools', this.requestPOSTHeader(params)).then(this.handleResponse);
	}

	async getStakeBalance(params) {
		return fetch(this.apiURL + 'stake-balance', this.requestPOSTHeader(params)).then(this.handleResponse);
	}

	async stakeConfirmaction(params) {
		return fetch(this.apiURL + 'stake-confirm', this.requestPOSTHeader(params)).then(this.handleResponse);
	}

	async stakeApproveToken(params) {
		return fetch(this.apiURL + 'stake-approve', this.requestPOSTHeader(params)).then(this.handleResponse);
	}

	async subscriber(params) {
		return fetch(this.apiURL + 'subscriber', this.requestPOSTHeader(params)).then(this.handleResponse);
	}

	async getMarketTokens() {
		return fetch(this.apiURL + 'markets', this.requestGETHeader()).then(this.handleResponse);
	}

	async contactRequest(params) {
		return fetch(this.apiURL + 'contactus', this.requestPOSTHeader(params)).then(this.handleResponse);
	}

	async withdrawConfirmaction(params) {
		return fetch(this.apiURL + 'withdraw-confirm', this.requestPOSTHeader(params)).then(this.handleResponse);
	}

	async farmFinished(params) {
		return fetch(this.apiURL + 'finished', this.requestGETHeader(params)).then(this.handleResponse);
	}

	handleResponse(response) {
		return response.text().then(text => {
			if (!response.ok) {
				const error = response.statusText;

				return Promise.reject(error);
			}

			const data = text && JSON.parse(text);

			return Promise.resolve(data);
		});
	}
}

export default BaseService;
