import React, { useEffect, useState, useCallback, forwardRef, useImperativeHandle, useMemo } from "react";
import { useSendTransaction, usePrepareSendTransaction, useWaitForTransaction } from 'wagmi';
import { useDebounce } from 'use-debounce';

import { ethers, utils, BigNumber } from "ethers";
import { param } from "jquery";

const SwapUtil = forwardRef((props, ref) => {
    const [ contractTo, setContractTo ] = useState('');
    const [ contractData, setContractData ] = useState('');

    const [debouncedTo] = useDebounce(contractTo, 500);
    const [debouncedData] = useDebounce(contractData, 500);

    const [ spenderApproveClickShow, setSpenderApproveClickedShow] = useState(false);
    const [ spenderApproveClicked, setSpenderApproveClicked] = useState(false);

    const { config } = usePrepareSendTransaction({
        request: { data: debouncedData, to: debouncedTo, value: '0'},
        onError(data) {
            if (spenderApproveClickShow) {
                props.clickHandlerWarningError(data.code);
            }
        }
    });

    const { data, isLoading, isError, isSuccess, sendTransactionAsync } = useSendTransaction(config);

    const spenderAllow = useMemo(() => sendTransactionAsync? true : false , [debouncedTo, debouncedData]);
    const spenderDone = useMemo(() => isSuccess? true : false , [isSuccess]);

    const [ spenderHash, setSpenderHash] = useState('');

    const {data: spenderTransData, isSuccess: spenderTransSuccess, isError: spenderTransError} = useWaitForTransaction({
        hash: spenderHash,
        onSuccess(data) {
            if (data) {
                props.clickHandlerSpenderApprove({hash: data.transactionHash});
            }
        },
        onError(data) {
            let obj = { hash : ""};
            props.clickHandlerSpenderApprove(obj);
        }
    });

    useEffect(() => {
        if (spenderApproveClickShow && spenderAllow) {
            sendTransactionAsync();
        }
    }, [spenderAllow, spenderApproveClicked]);

    useEffect(() => {
        if (spenderDone) {
            setContractTo('');
            setContractData('');
            setSpenderApproveClickedShow(false);

            setSpenderHash(data.hash);
        }

    }, [spenderDone]);

    useEffect(() => {
        if (isError) {
            setContractTo('');
            setContractData('');
            setSpenderApproveClickedShow(false);

            let obj = { hash : ""};
            props.clickHandlerSpenderApprove(obj);
        }
    }, [isError]);

    const [ swapTo, setSwapTo ] = useState('');
    const [ swapData, setSwapData ] = useState('');
    const [ swapValue, setSwapValue ] = useState('');
    const [ swapFee, setSwapFee ] = useState(0);

    const [debouncedSwapTo] = useDebounce(swapTo, 500);
    const [debouncedSwapData] = useDebounce(swapData, 500);
    const [debouncedSwapValue] = useDebounce(swapValue, 500);

    const [ swapConfirmClickShow, setSwapConfirmClickedShow] = useState(false);
    const [ swapConfirmClicked, setSwapConfirmClicked] = useState(false);

    const { config: swapConfig } = usePrepareSendTransaction({
        // request: { data: debouncedSwapData, to: debouncedSwapTo, value: swapValue, maxFeePerGas: swapFee, maxPriorityFeePerGas: swapFee},
        request: { data: debouncedSwapData, to: debouncedSwapTo, value: swapValue},
        onError(data) {
            if (swapConfirmClickShow) {
                props.clickHandlerWarningError(data.code);
            }
        }
    });

    const { data: swapConfigData, isLoading: swapLoading, isError: swapError, isSuccess: swapSuccesss, sendTransactionAsync: swapSendTransactionAsync } = useSendTransaction(swapConfig);

    const swapAllow = useMemo(() => swapSendTransactionAsync? true : false , [swapSendTransactionAsync]);
    const swapDone = useMemo(() => swapSuccesss? true : false , [swapSuccesss]);

    const [ swapHash, setSwapHash] = useState('');

    const {data: swapTransData, isSuccess: swapTransSuccess, isError: swapTransError} = useWaitForTransaction({
        hash: swapHash,
        onSuccess(data) {
            if (data) {
                props.clickHandlerSwapConfirmed({hash: data.transactionHash});
            }
        },
        onError(data) {
            let obj = { hash : ""};
            props.clickHandlerSwapConfirmed(obj);
        }
    });

    useEffect(() => {
        if (swapAllow) {
            swapSendTransactionAsync();
        }
    }, [swapAllow, swapConfirmClicked]);

    useEffect(() => {
        if (swapDone) {
            setSwapTo('');
            setSwapData('');
            setSwapValue('');
            setSwapConfirmClickedShow(false);

            setSwapHash(swapConfigData.hash);
        }

    }, [swapDone]);

    useEffect(() => {
        if (swapError) {
            setSwapTo('');
            setSwapData('');
            setSwapValue('');
            setSwapFee('');
            setSwapConfirmClickedShow(false);

            let obj = { hash : ""};
            props.clickHandlerSwapConfirmed(obj);
        }
    }, [swapError]);

    useImperativeHandle(ref, () => ({
        spenderApprove(params) {
            setContractTo(params.to);
            setContractData(params.data);

            setSpenderApproveClickedShow(true);
            setSpenderApproveClicked(!spenderApproveClicked);
        },
        swapConfirm(params) {
            setSwapTo(params.to);
            setSwapData(params.data);
            setSwapValue(params.value);
            setSwapFee(params.fee);

            setSwapConfirmClickedShow(true);
            setSwapConfirmClicked(!swapConfirmClicked);
        }
    }));
});

export default SwapUtil;