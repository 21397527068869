import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import '@popperjs/core/lib/popper.js';
import 'bootstrap/dist/js/bootstrap.min.js';

import './assets/font-awesome/css/font-awesome.min.css';
import './assets/css/user.css';
import './assets/css/usermedia.css';

import 'simplebar/dist/simplebar.js';

import Home from './pages/home/Home';
import Dashboard from './pages/dashboard/Dashboard';
// import Buy from './pages/buy/Buy';
import Farms from './pages/stake/Farms';
import Pools from './pages/stake/Pools';
import Swap from './pages/swap/Swap';
import Exchange from './pages/exchange/Exchange';

import Martkets from './pages/markets/Market';
import TermsAndCondition from './pages/cms/Terms';
import PrivacyPolicy from './pages/cms/Privacy';

import LandingLayout from './components/layouts/LandingLayout';
import AppLayout from './components/layouts/AppLayout';

function App() {
    return (
        <Router>
            <Routes>
                <Route exact="true" path="/" element={<AppLayout />}>
                    <Route index element={<Dashboard />}></Route>
                </Route>

                <Route exact="true" path="/home" element={<LandingLayout />}>
                    <Route index element={<Home />}></Route>
                </Route>

                <Route path="/dashboard" element={<AppLayout />}>
                    <Route index element={<Dashboard />}></Route>
                </Route>

                <Route path="/swap" element={<AppLayout />}>
                    <Route index element={<Swap />}></Route>
                </Route>

                <Route path="/liquidity" element={<AppLayout />}>
                    <Route index element={<Exchange />}></Route>
                </Route>

                <Route path="/farms" element={<AppLayout />}>

                    <Route index element={<Farms />}></Route>
                </Route>

                <Route path="/pools" element={<AppLayout />}>
                    <Route index element={<Pools />}></Route>
                </Route>

                <Route path="/terms" element={<LandingLayout />}>
                    <Route index element={<TermsAndCondition />}></Route>
                </Route>

                <Route path="/privacy-policy" element={<LandingLayout />}>
                    <Route index element={<PrivacyPolicy />}></Route>
                </Route>

                <Route path="/markets" element={<LandingLayout />}>
                    <Route index element={<Martkets />}></Route>
                </Route>
            </Routes>
        </Router>
    );
}

export default App;
