import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import eventBus from "../../events/EventBus";

import profile from './../../assets/images/profile.svg';
import wallet from './../../assets/images/wallet.svg';
import metamasklogo from './../../assets/images/metamasklogo.svg';
import coinbaselogo from './../../assets/images/coinbaselogo.svg';

import {Buffer} from 'buffer';

import { useAccount, useConnect, useDisconnect, useBalance, useEnsName } from 'wagmi';
import { useSendTransaction, usePrepareSendTransaction, useSignTypedData } from 'wagmi';
import { useDebounce } from 'use-debounce';

window.Buffer = window.Buffer || Buffer;

  export default function ConnectWallet(props) {
    const { address, connector, isConnected } = useAccount();
    const { connectors, pendingConnector } = useConnect();

    const [ dto, setTo ] = useState();
    const [ ddata, setData ] = useState();
    const [ cnt, setCnt ] = useState(0);

    const [debouncedTo] = useDebounce(dto, 500);
    const [debouncedData] = useDebounce(ddata, 500);
    const { connect } = useConnect({
        onSettled() {
            eventBus.dispatch("connectWallet", {status: true});
        }
    });

    const { disconnect } = useDisconnect({
        onSuccess() {
            setModalIsOpenToFalse();
            eventBus.dispatch("disconnectWallet", {status: true});
        }
    });

    const { data: mybal } = useBalance({address: address,watch: true,chainId: 5});
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const setModalIsOpenToTrue =()=>{
        setModalIsOpen(true);
    }

    const setModalIsOpenToFalse =()=>{
        setModalIsOpen(false);
    }

    useEffect(() => {
        if (isConnected) {
            localStorage.setItem("address", address);
            localStorage.setItem("balance", mybal ? mybal.formatted : 0);
            localStorage.setItem("token", "ETH");
        } else {
            localStorage.setItem("address", "");
            localStorage.setItem("balance", 0);
            localStorage.setItem("token", "ETH");
        }
    });

    if (isConnected) {
        return (
            (props.name === "menu") ?
                <li className="nav-item dropdown profiledrop">
                <a className="nav-link dropdown-bs-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span>{address.substring(0, 10)}…{address.substring(address.length - 10)}</span>
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <div className="walletdrop">
                        <img src={profile} className="profileicon" alt={profile} />
                        <p><span>{ address }</span></p>
                        <div className="text-center">
                            <button onClick={ disconnect } className="btn sitebtn">Disconnect Wallet</button>
                        </div>
                    </div>
                </div>
            </li>
            :

            <> <a className="sitebtn btn-block"> { address } </a></>
        )
    }
   
    return (
        <>
            { (props.name === "menu") ?
                <li className="nav-item custom-menu-li-style">
                    <button className="btn sitebtn" onClick={setModalIsOpenToTrue}>Connect Wallet</button>
                </li>

            :
                <>
                    <img src={wallet} className="walletboxicon" alt={wallet}/>
                    <h4>Please Connect Wallet</h4>
                    <button className="btn sitebtn btn-block" onClick={setModalIsOpenToTrue}>Connect Wallet</button>
                </>
            }

            <Modal
                    show={modalIsOpen}
                    onHide={() => setModalIsOpenToFalse }
                    dialogClassName="modalbgt"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Connect to a wallet</Modal.Title>
                    <button className="close" onClick={setModalIsOpenToFalse}>&times;</button>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center">Start by connecting with one of the wallets below. Be sure to store your private keys or seed phrase securely. Never share them with anyone.</p>

                    <div className="mt-4">
                        <div className="siteformbox">
                            <div className="contentbox">
                                <ul className="paymentlogo">
                                    {connectors.map((connector, i) => (
                                        <li key={i}>
                                            <a id="walletconnect" className="bluedarkbox" disabled={!connector.ready} onClick={() => connect({ connector })}>

                                                { connector.name == "MetaMask" && 
                                                    <img src={metamasklogo} alt={metamasklogo} />
                                                }

                                                { connector.name == "Coinbase Wallet" && 
                                                    <img src={coinbaselogo} alt={coinbaselogo} />
                                                }
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}