import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { WagmiConfig, createClient, configureChains } from 'wagmi';
import { mainnet, sepolia, goerli } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import { infuraProvider } from 'wagmi/providers/infura';

import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';

const { chains, provider, webSocketProvider } = configureChains(
    [sepolia],
    // [mainnet, goerli, sepolia],
    [infuraProvider({ apiKey: '14c05da1a4c24ab7ba34ab1c698ebdd6' }), publicProvider()]
);

const client = createClient({
    autoConnect: true,
    connectors: [
      new MetaMaskConnector({ chains }),
      new CoinbaseWalletConnector({
        chains:[sepolia],
        options: {
          appName: 'wagmi'
        }
      })
    ],
    provider,
    webSocketProvider
  })

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <WagmiConfig client={client}>
        <App />
    </WagmiConfig>
);