import React from "react";
import { Link } from 'react-router-dom';

import staked from './../../assets/images/staked.svg';
import stakedlp from './../../assets/images/staked-lp.svg';
import money from './../../assets/images/money.svg';
import apy from './../../assets/images/apy.svg';
import eth from './../../assets/images/color/eth.svg';
import usdt from './../../assets/images/color/usdt.svg';
import btc from './../../assets/images/color/btc.svg';
import shib from './../../assets/images/color/shib.svg';
import xrp from './../../assets/images/color/xrp.svg';

class Dashboard extends React.Component {
    render() {
return <article className="gridparentbox">
            <div className="container sitecontainer">
                <section className="innerpagecontent stackedbanner">
                    <div className="topcontentbox">
                        <h3 className="heading-title pb-2">Dashboard</h3>
                    </div>
                </section>

                <div className="priceboxtable">
                    <div className="pricerowbox">
                        <div className="priceflexbox">
                            <div className="priceboxouter">
                                <div className="panelcontentbox">
                                    <div className="livepricebox">
                                        <div className="livepricetable">
                                            <div className="h4">Total Value<br/><span className="h3">$865,196.74</span></div>
                                            <div><img src={money} /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="priceboxouter">
                                <div className="panelcontentbox">
                                    <div className="livepricebox">
                                        <div className="livepricetable">
                                            <div className="h4">Total Staked <br/><span className="h3">$865,196.74</span> </div>
                                            <div><img src={staked} /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="priceboxouter">
                                <div className="panelcontentbox">
                                    <div className="livepricebox">
                                        <div className="livepricetable">
                                            <div className="h4">Total LP Staked<br/><span className="h3">$865,196.74</span> </div>
                                            <div><img src={stakedlp} /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flexbox stakeflexbox">
                    <div className="stakepanel">
                        <h2 className="heading-box">Earn + Fees in Farms</h2>
                        <div className="tabrightbox"> <Link to="/farms" className="alink"><i className="fa fa-arrow-right"></i></Link> </div>
                        <div className="panelcontentbox">
                            <div className="staketablebox">
                                <div><img src={eth} className="coinicon" /><img src={btc} className="coinicon coinlefticon" />USDT - ETH</div>
                                <div>APY<img src={apy} className="ms-2 apyicon" /><br/><span className="t-gray">2.3%</span></div>
                                <div className="text-right"><a href="#" className="btn sitebtn view-btn">Start Farm</a></div>
                            </div>
                            <div className="staketablebox">
                                <div><img src={usdt} className="coinicon" /><img src={shib} className="coinicon coinlefticon" />USDT - ETH</div>
                                <div>APY<img src={apy} className="ms-2 apyicon" /><br/><span className="t-gray">2.3%</span></div>
                                <div className="text-right"><a href="#" className="btn sitebtn view-btn">Start Farm</a></div>
                            </div>
                            <div className="staketablebox">
                                <div><img src={xrp} className="coinicon" /><img src={eth} className="coinicon coinlefticon" />USDT - ETH </div>
                                <div>APY<img src={apy} className="ms-2 apyicon" /><br/><span className="t-gray">2.3%</span></div>
                                <div className="text-right"><a href="#" className="btn sitebtn view-btn">Start Farm</a></div>
                            </div>
                            <div className="staketablebox">
                                <div><img src={btc} className="coinicon" /><img src={shib} className="coinicon coinlefticon" />USDT - ETH</div>
                                <div>APY<img src={apy} className="ms-2 apyicon" /><br/><span className="t-gray">2.3%</span></div>
                                <div className="text-right"><a href="#" className="btn sitebtn view-btn">Start Farm</a></div>
                            </div>
                        </div>
                    </div>
                    <div className="stakepanel">
                        <h2 className="heading-box">Launchpools</h2>
                        <div className="tabrightbox"> <Link to="/pools" className="alink"><i className="fa fa-arrow-right"></i></Link> </div>
                            <div className="panelcontentbox">
                                <div className="staketablebox">
                                    <div><img src={shib} className="coinicon coinleft" />Holder Pool <br/><span className="t-gray">Stake - Earn</span></div>
                                    <div>APY<img src={apy} className="ms-2 apyicon" /><br/><span className="t-gray">2.3%</span></div>
                                    <div className="text-right"><a href="#" className="btn sitebtn view-btn">Start Farm</a></div>
                                </div>
                                <div className="staketablebox">
                                    <div><img src={xrp} className="coinicon coinleft" />Holder Pool <br/><span className="t-gray">Stake - Earn</span> </div>
                                    <div>APY<img src={apy} className="ms-2 apyicon" /><br/><span className="t-gray">2.3%</span></div>
                                    <div className="text-right"><a href="#" className="btn sitebtn view-btn">Start Farm</a></div>
                                </div>
                                <div className="staketablebox">
                                    <div><img src={btc} className="coinicon coinleft" />Holder Pool <br/><span className="t-gray">Stake - Earn</span> </div>
                                    <div>APY<img src={apy} className="ms-2 apyicon" /><br/><span className="t-gray">2.3%</span></div>
                                    <div className="text-right"><a href="#" className="btn sitebtn view-btn">Start Farm</a></div>
                                </div>
                                <div className="staketablebox">
                                <div><img src={eth} className="coinicon coinleft" />Holder Pool <br/><span className="t-gray">Stake - Earn</span> </div>
                                <div>APY<img src={apy} className="ms-2 apyicon" /><br/><span className="t-gray">2.3%</span></div>
                                <div className="text-right"><a href="#" className="btn sitebtn view-btn">Start Farm</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    }
}

export default Dashboard;