import React from "react";

class TokenListComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			search: ""
		};

		this.searchEvent = this.searchEvent.bind(this);
	}

	searchEvent = (event) => {
		this.setState({search: event.target.value});
	}

	render() {
		return (
			<>
				<div className="contentbox">
					<div className="form-group">
						<div className="input-group dateinput">
							<input className="form-control" placeholder="Search Symbol" value={this.state.search} onChange={this.searchEvent} />
							<div className="input-group-append"><span className="input-group-text"><i className="fa fa-search"></i></span></div>
						</div>
					</div>
				</div>

				<ul className="cryptoselect" data-simplebar>
					{ this.props.data.length > 0 &&
						this.props.data.map((data,i) =>(
							<li 
								className={ ((i == 0) ? 'active' : '') }
								key={i}
								onClick={(e) => this.props.clickHandler(data.slug, data.contract_address, this.props.name)}
								className={`${(this.props.filterTxtName == data.slug) ? 'common-loading pe-none' : ''} ${ (this.state.search && data.slug.toLowerCase().indexOf(this.state.search.toLocaleLowerCase()) != -1) ? '' : ((this.state.search.length == 0) ? '' : 'd-none')} `}
							>
								<img src={data.logo_url} alt={ data.name } className="coinicon" /> { data.name }
								<span className="text-right t-gray">{ data.slug }</span>
							</li>
						))
					}
				</ul>
			</>
		);
	}
}

export default TokenListComponent;