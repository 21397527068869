import React from "react";
import { Link } from 'react-router-dom';

import darklogo from './../../assets/images/darklogo.svg' ;

import BaseService from "../../services/common/BaseService";

import Newsletter from "../Newsletter/Newsletter";

class LandingFooter extends React.Component {
    constructor(props) {
        super(props);

        this.service = new BaseService();

        this.state = {
            footer: ""
        };
    }

    componentDidMount() {
    }

    getYear() {
        return new Date().getFullYear();
    }

    loadFooter() {
        this.service.getHomeFooter().then(res => {
            if (res.data) {
                this.setState({footer:res.data.footer});
            }
        });
    }

    render() {
        return(<footer className="footerbottom homefooter">
                <section className="footer-gray-bg fnt-reg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-6">
                                <div className="footlistbg">
                                    <div className="footlofo"> <img src={darklogo} /> </div>
                                    <p className="content">Integer ornare tempor nunc, nec iaculis justo suscipit at. In hac habitasse platea dictumst. Fusce ex nisi, maximus ac consequat non, mattis egestas lacus. </p>
                                    <ul className="sociallist">
                                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6">
                                <h3 className="h3">Menu</h3>
                                <ul className="foot-list">
                                    <li>
                                        <Link to="/swap">Trade</Link>
                                    </li>
                                    <li>
                                        <Link to="/farms">Earn</Link>
                                    </li>
                                    <li>
                                        <Link to="/">About Us</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-2 col-md-6">
                                <h3 className="h3">Links</h3>
                                <ul className="foot-list">
                                    <li>
                                        <Link to="/terms">Terms and Conditions</Link>
                                    </li>
                                    <li>
                                        <Link to="/privacy-policy">Privacy Policy</Link>
                                    </li>
                                    <li>
                                        <Link to="/markets">Markets</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <Newsletter></Newsletter>
                            </div>
                        </div>
                        <div className="text-center">
                        <p className="ftext">{this.getYear()} © { process.env.REACT_APP_NAME }. All Rights Reserved.</p>
                        </div>
                    </div>
                </section>
            </footer>
        )
    }
}

export default LandingFooter;