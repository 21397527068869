import React from "react";
import { Link } from 'react-router-dom';

import ConnectWallet from '../connectwallet/ConnectWallet';
import Theme from '../theme/Theme';

import logo from './../../assets/images/logo.svg' ;
import profile from './../../assets/images/profile.svg';
import eth from './../../assets/images/color/eth.svg';
import usdt from './../../assets/images/color/usdt.svg';
import link from './../../assets/images/color/link.svg';
import shib from './../../assets/images/color/shib.svg';

import homeicon from './../../assets/images/homeicon.svg';
import tradeicon from './../../assets/images/tradeicon.svg';
import logouticon from './../../assets/images/logouticon.svg';
import earnicon from './../../assets/images/earnicon.svg';
import infoicon from './../../assets/images/infoicon.svg';
import marketicon from './../../assets/images/marketicon.svg';
import menubar from './../../assets/images/menubar.svg';
import liquidityicon from './../../assets/images/liquidityicon.svg';

import $ from 'jquery';

class AppHeader extends React.Component {
    menuActiveIndicator = (name, altname='') => {
        return (window.location.pathname === name || (altname && window.location.pathname == altname)) ? 'active' : '';
    }

    sidebarCollapse = () => {
        document.body.classList.remove("nightmode");

        $(".leftsidemenu").toggleClass("active");
        $("body").toggleClass("pagewrapperbox");
        $(".overlay").addClass("active");
    }

    closeCollapse = () => {
        $(".leftsidemenu").removeClass("active");
        $("body").removeClass("pagewrapperbox");
        $(".overlay").removeClass("active");
    }

    menuitem = () => {
        $(".leftsidemenu").removeClass("active");
        $("body").removeClass("pagewrapperbox");
        $(".overlay").removeClass("active");
    }

    render() {
        return (<><div className="overlay"></div>
            <header className="headermenu">
            <nav className="navbar navbar-expand-md navbar-dark headbg userheader">
                <div className="container">
                    <Link className="navbar-brand" to="/home"> <img src={logo} className="logo" /></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar"> <span className="navbar-toggler-icon"></span> </button>
                    <div className="mobiletoggle">
                        <button type="button" onClick={() => this.sidebarCollapse()} className="btn sidebtntoggle"><img src={menubar} /></button>
                    </div>

                    <div className="collapse navbar-collapse" id="collapsibleNavbar">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item dropdown coinselectbox">
                                <a href="#" role="button" className="nav-link dropdown-bs-toggle" data-bs-toggle="dropdown">
                                    <img src={eth} className="coinicon" />Ethereum<i className="fa fa-caret-down"></i>
                                </a>

                                <div className="dropdown-menu">
                                    <a href="#" className="nav-link dropdown-item"><img src={eth} className="coinicon"/>Ethereum</a>
                                </div>
                            </li>

                            <ConnectWallet name="menu"></ConnectWallet>
                        </ul>
                    </div>
                </div>
            </nav>

            <div className="leftsidemenu">
                <div onClick={() => this.closeCollapse()} className="closeiconbtn">
                    <i className="fa fa-times"></i>
                </div>
                <div className="leftsidescroll">
                    <ul>
                        <li onClick={() => this.menuitem()} className={`nav-item ${ this.menuActiveIndicator('/dashboard') }`}><Link to="/dashboard" className={`nav-link ${ this.menuActiveIndicator('/dashboard') }`}><i><img src={homeicon}  /></i><div>Dashboard</div></Link></li>
                        <li onClick={() => this.menuitem()} className={`nav-item ${ this.menuActiveIndicator('/swap') }`}><Link to="/swap" className={`nav-link ${ this.menuActiveIndicator('/swap', '') }`}><i><img src={tradeicon}  /></i><div>Swap</div></Link></li>
                        <li onClick={() => this.menuitem()} className={`nav-item ${ this.menuActiveIndicator('/liquidity') }`}><Link to="/liquidity" className={`nav-link ${ this.menuActiveIndicator('/liquidity') }`}><i><img src={liquidityicon}  /></i><div>Liquidity</div></Link></li>
                        <li onClick={() => this.menuitem()} className={`nav-item ${ this.menuActiveIndicator('/farms') }`}><Link to="/farms" className={`nav-link ${ this.menuActiveIndicator('/farms') }`}><i><img src={earnicon}  /></i><div>Earn</div></Link></li>
                        <li onClick={() => this.menuitem()} className={`nav-item ${ this.menuActiveIndicator('/markets') }`}><Link to="/markets" className={`nav-link ${ this.menuActiveIndicator('/markets') }`}><i><img src={marketicon}  /></i><div>Markets</div></Link></li>
                    </ul>
                </div>   
            </div>
        </header></>
        )
    }
}

export default AppHeader;
