import React from "react";
import {Link} from 'react-router-dom';
import usdt from './../../assets/images/color/usdt.svg';
import eth from './../../assets/images/color/eth.svg';
import link from './../../assets/images/color/link.svg';
import shib from './../../assets/images/color/shib.svg';
import downarrow from './../../assets/images/downarrow.svg';
import apy from './../../assets/images/apy.svg';
import arrow from './../../assets/images/arrow.svg';
import lock from './../../assets/images/lock.svg';
import nodata from './../../assets/images/nodata.svg';

import ReactLoading from 'react-loading';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Modal from 'react-bootstrap/Modal';

import ConnectWallet from '../../components/connectwallet/ConnectWallet';

import FarmsService from "../../services/farms/FarmsService";

import eventBus from "../../events/EventBus";

import StakeUtil from "../../hooks/StakeUtil";

class Farms extends React.Component {
	constructor(props) {
		super(props);

		this.stakeUtilRef = React.createRef();

		this.service = new FarmsService();

		this.state = {
			isLiveLoading: false,
			live: [],
			finished: [],
			isFinishedLoading: false,
			staked: [],
			isStakedLoading: false,
			isShowStake: false,
			isShowEnable: false,
			filterStakePairTxt: "",
			filterStakePairBal: 0,
			stakeAmt: 0,
			stakeAllowence: 0,
			stakeAlert: false,
			isStakeLoading: false,
			isShowStakeAllowence: false,
			isShowStakeConfirm: false,
			stakePosition: null,
			stakeApprovePosition: null,
			isShowWithdraw: false,
			filterWithdrawPairBal: 0,
			isWithdrawLoading: false,
			withdrawAlert: false,
			withdrawAmt: 0,
			filterWithdrawPairTxt: "",
			withdrawPosition: null,
			isShowUnstake: false,
			isUnstakeLoading: false,
			unstakeAmt: 0,
			unstakePosition: null,
			unstakeOrgPosition: null
		};

		this.stakeAmtCalInputEvent = this.stakeAmtCalInputEvent.bind(this);
	}

	componentDidMount() {
		this.getFarms("init");
		this.getFarmFinished();

		eventBus.on("connectWallet", (data) => {
			this.setState({live: [], staked: [], finished: []});
			this.getFarms("init");
		});

		eventBus.on("disconnectWallet", (data) => {
			this.setState({live: [], staked: [], finished: []});
			this.getFarms("init");
		});
	}

	getFarms(type) {
		var params = {
			address: this.service.address()
		};

		if (type == "init") {
			this.setState({isLiveLoading: true, isStakedLoading: true, isFinishedLoading: true});
		} else if(type == "live") {
			this.setState({isLiveLoading: true});
		} else if(type == "staked") {
			this.setState({isStakedLoading: true});
		}
		//  else if (type == "finish") {
		// 	// this.setState({isFinishedLoading: true});
		// }
		 else if (type == "withdraw") {
			this.setState({isFinishedLoading: true, isStakedLoading: true});
		} else if (type == "unstake") {
			this.setState({isLiveLoading: true});
		}

        this.service.getFarms(params).then(res => {
            if (res.success) {
				if (type == "init") {
                	this.setState({live: res.data.farm_info, isLiveLoading: false});
					this.updateStaked(res.data.farm_info);
					// this.updateFinished(res.data.farm_info);
				} else if (type == "live") {
					this.setState({live: res.data.farm_info, isLiveLoading: false});
				} else if (type == "staked") {
					this.updateStaked(res.data.farm_info);
				}
				// else if (type == "finish") {
				// 	// this.updateFinished(res.data.farm_info);
				// }
				else if (type == "withdraw") {
					this.updateStaked(res.data.farm_info);
					// this.updateFinished(res.data.farm_info);
				} else if (type == "unstake") {
					this.setState({live: res.data.farm_info, isLiveLoading: false});
					// this.updateFinished(res.data.farm_info);
				}
            }
        });
	}

	getFarmFinished() {
		this.service.farmFinished().then(res => {
			if (res.success) {
				this.setState({finished: res.data, isFinishedLoading: false});
			}
		});
	}

	updateStaked = (info) => {
		let stakedInfo = [];

		if (info) {
			stakedInfo = info.filter((data, index) => {
				if (data.userInfo && parseFloat(data.userInfo.amount) > 0) {
					data.pairData.pid = index;
					return data;
				}
			});
		}

		this.setState({staked: stakedInfo, isStakedLoading: false});
	}

	updateFinished = (info) => {
		let finishedInfo = [];

		if (info) {
			finishedInfo = info.filter((data) => {
				if (data.userInfo && parseFloat(data.userInfo.amount) == 0) {
					return data;
				}
			});
		}

		this.setState({finished: finishedInfo, isFinishedLoading: false});
	}

	stakedtabfirst = () => {
		document.querySelector("#card .tab-content>div:first-child").classList.add('show');
		document.querySelector("#card .tab-content>div:first-child").classList.add('active');

		document.querySelector("#card .tab-content>div:nth-child(2)").classList.remove('show');
		document.querySelector("#card .tab-content>div:nth-child(2)").classList.remove('active');

		document.querySelector("#card .tab-content>div:last-child").classList.remove('show');
		document.querySelector("#card .tab-content>div:last-child").classList.remove('active');

		document.querySelector("#list .tab-content>div:first-child").classList.add('show');
		document.querySelector("#list .tab-content>div:first-child").classList.add('active');

		document.querySelector("#list .tab-content>div:nth-child(2)").classList.remove('show');
		document.querySelector("#list .tab-content>div:nth-child(2)").classList.remove('active');

		document.querySelector("#list .tab-content>div:last-child").classList.remove('show');
		document.querySelector("#list .tab-content>div:last-child").classList.remove('active');
	}

	stakedtabmiddle = () => {
		document.querySelector("#card .tab-content>div:first-child").classList.remove('show');
		document.querySelector("#card .tab-content>div:first-child").classList.remove('active');

		document.querySelector("#card .tab-content>div:nth-child(2)").classList.add('show');
		document.querySelector("#card .tab-content>div:nth-child(2)").classList.add('active');

		document.querySelector("#card .tab-content>div:last-child").classList.remove('show');
		document.querySelector("#card .tab-content>div:last-child").classList.remove('active');

		document.querySelector("#list .tab-content>div:first-child").classList.remove('show');
		document.querySelector("#list .tab-content>div:first-child").classList.remove('active');

		document.querySelector("#list .tab-content>div:nth-child(2)").classList.add('show');
		document.querySelector("#list .tab-content>div:nth-child(2)").classList.add('active');

		document.querySelector("#list .tab-content>div:last-child").classList.remove('show');
		document.querySelector("#list .tab-content>div:last-child").classList.remove('active');
	}

	stakedtablast = () => {
		document.querySelector("#card .tab-content>div:first-child").classList.remove('show');
		document.querySelector("#card .tab-content>div:first-child").classList.remove('active');

		document.querySelector("#card .tab-content>div:nth-child(2)").classList.remove('show');
		document.querySelector("#card .tab-content>div:nth-child(2)").classList.remove('active');

		document.querySelector("#card .tab-content>div:last-child").classList.add('show');
		document.querySelector("#card .tab-content>div:last-child").classList.add('active');

		document.querySelector("#list .tab-content>div:first-child").classList.remove('show');
		document.querySelector("#list .tab-content>div:first-child").classList.remove('active');

		document.querySelector("#list .tab-content>div:nth-child(2)").classList.remove('show');
		document.querySelector("#list .tab-content>div:nth-child(2)").classList.remove('active');

		document.querySelector("#list .tab-content>div:last-child").classList.add('show');
		document.querySelector("#list .tab-content>div:last-child").classList.add('active');
	}

	showHideStakePopup = () => {
		this.setState(prevState => ({
            isShowStake: !prevState.isShowStake,
			stakeAmt: 0
        }));
	}

	filterStakeEvent = (data, i) => {
		var pairName = data.pairData.token_one_name + "-"+ data.pairData.token_two_name;

		this.setState({isShowStake: true, isStakeLoading: true, filterStakePairTxt: pairName, stakePosition: i, stakeAllowence: data.pairData.allowance});
		
		var params = {
			pair: data.pairData.pair_address,
			address: this.service.address()
		};

		this.service.getStakeBalance(params).then(res => {
            if (res.success) {
                this.setState({filterStakePairBal: res.data.balance.toFixed(4) , isStakeLoading: false});
            }
        });
	}

	stakeAmtCalInputEvent = (event) => {
        var inAmt = event.target.value.replace(/[^0-9\.]/g,'');

        if (inAmt.split('.').length > 2) {
            return false;
        }

		var isStakeAlert = false;

		if (parseFloat(inAmt) > parseFloat(this.state.filterStakePairBal)) {
			isStakeAlert = true;
		}

		var isAllowenceAlert = false;

		if (parseFloat(inAmt) > parseFloat(this.state.stakeAllowence)) {
			isAllowenceAlert = true;
		}

		this.setState({stakeAlert: isStakeAlert, stakeAmt: inAmt, isShowStakeAllowence: isAllowenceAlert});
    }

	confirmEvent = () => {
		var params = {
			address: this.service.address(),
			amount: this.state.stakeAmt,
			position: this.state.stakePosition
		};

		this.setState({isStakeLoading: true});

		this.stakeUtilRef.current.confirmEvt(params);
	}

	stakeApprove = (data, i) => {
		var params = {
			contractaddress: data.pairData.pair_address,
			amount: process.env.REACT_APP_MAX_TOKEN_LIMIT
		};

		this.setState({stakeApprovePosition: i});

		this.stakeUtilRef.current.stakeApproveEvt(params);
	}

	stakeApproved = (data) => {
		if (data.hash) {
			toast.success("Approved successfully");

			this.updateStakeApprove(this.state.stakeApprovePosition, process.env.REACT_APP_MAX_TOKEN_LIMIT);
		} else {
			toast.error("Approve Cancelled");
		}
	}

	stakeWarning = (data) => {
		toast.error(data);

		this.setState({isStakeLoading: false, isShowStake: false});
	}

	updateStakeApprove(position, amount) {
		let tot = parseFloat(this.state.live[position].pairData.allowance) + parseFloat(amount);
		this.state.live[position].pairData.allowance = tot.toString();

		this.setState({live: this.state.live});
	}

	updateStakeInfo(position, amount) {
		let tot = parseFloat(this.state.live[position].userInfo.amount) + parseFloat(amount);

		this.state.live[position].userInfo.amount = tot.toString();

		this.setState({live: this.state.live});
	}

	showHideWithdrawPopup = () => {
		this.setState(prevState => ({
            isShowWithdraw: !prevState.isShowWithdraw,
			withdrawAmt: 0
        }));
	}

	filterWithdrawEvent = (data, i) => {
		var pairName = data.pairData.token_one_name + "-"+ data.pairData.token_two_name;

		this.setState({isShowWithdraw: true, filterWithdrawPairTxt: pairName, withdrawPosition: i, filterWithdrawPairBal: data.userInfo.amount});
	}

	withdrawAmtCalInputEvent = (event) => {
        var inAmt = event.target.value.replace(/[^0-9\.]/g,'');

        if (inAmt.split('.').length > 2) {
            return false;
        }

		var isLocalWithdrawAlert = false;

		if (parseFloat(inAmt) > parseFloat(this.state.filterWithdrawPairBal)) {
			isLocalWithdrawAlert = true;
		}

		this.setState({isWithdrawAlert: isLocalWithdrawAlert, withdrawAmt: inAmt});
    }

	confirmWithdrawEvent = () => {
		var params = {
			address: this.service.address(),
			amount: this.state.withdrawAmt,
			position: this.state.withdrawPosition
		};

		this.setState({isWithdrawLoading: true});

		this.stakeUtilRef.current.confirmWithdrawEvt(params);
	}

	withdrawConfirmed = (data) => {
		if (data.hash) {
			toast.success("Withdrawal successfully");

			this.updateWithdraw(this.state.withdrawPosition, this.state.withdrawAmt);
			this.setState({isShowWithdraw: false, withdrawAmt: 0, isWithdrawLoading: false});

			this.getFarms("withdraw");
		} else {
			toast.error("Withdrawal Cancelled");

			this.setState({withdrawAmt: 0, isWithdrawLoading: false});
		}
    }

	updateWithdraw(position, amount) {
		let tot = parseFloat(this.state.live[position].userInfo.amount) - parseFloat(amount);
		this.state.live[position].userInfo.amount = tot.toString();

		this.setState({live: this.state.live});
	}

	getImageSource(name) {
		var path = "assets/userpanel/images/color/" + name.toLowerCase() + ".svg";
		return path;
	}

	showHideUnstakePopup = () => {
		this.setState(prevState => ({
            isShowUnstake: !prevState.isShowUnstake,
			isUnstakeLoading: !prevState.isUnstakeLoading
        }));
	}

	filterUnstakeEvent = (data, i) => {
		var pairName = data.pairData.token_one_name + "-"+ data.pairData.token_two_name;
		this.setState({isShowUnstake: true, unstakePosition: i, unstakeOrgPosition: data.pairData.pid, unstakeAmt: data.userInfo.amount});
	}

	unstakeConfirmEvent = () => {
		var params = {
			address: this.service.address(),
			amount: this.state.unstakeAmt,
			position: this.state.unstakeOrgPosition
		};

		this.setState({isUnstakeLoading: true});

		this.stakeUtilRef.current.confirmUnStakeEvt(params);
	}

	unStakeConfirmed = (data) => {
		if (data.hash) {
			toast.success("Unstaked successfully");

			this.setState({isShowUnstake: false, unstakeAmt: 0, isUnstakeLoading: false});
			//this.updateUnstakeInfo(this.state.unstakePosition, this.state.unstakeAmt);

			this.state.staked.splice(this.state.unstakePosition, 1);

			this.setState({staked: this.state.staked});

			this.getFarms("unstake");
		} else {
			toast.error("Unstake Cancelled");

			this.setState({unstakeAmt: 0, isUnstakeLoading: false});
		}
	}

	updateUnstakeInfo(position, amount) {
		let tot = parseFloat(this.state.staked[position].userInfo.amount) - parseFloat(amount);
		this.state.staked[position].userInfo.amount = tot.toString();

		this.setState({staked: this.state.staked});
	}

	stakeConfirmed = (data) => {
		if (data.hash) {
			toast.success("Staked successfully");

			this.setState({isShowStake: false, stakeAmt: 0, isStakeLoading: false});
			this.updateStakeInfo(this.state.stakePosition, this.state.stakeAmt);

			this.getFarms("staked");
		} else {
			toast.error("Stake Cancelled");

			this.setState({isStakeLoading: false, stakeAmt: 0});
		}
	}

	render() {
		return <div>
					<article className="gridparentbox exchangegridbox">
						<section className="topbanner panelcontentbox">
							<div className="container sitecontainer">
								<ul className="nav nav-tabs tabbanner innerpagetab">
									<li className="nav-item">
										<Link to="/farms" className="nav-link active">Farms</Link>
									</li>
									<li className="nav-item">
										<Link to="/pools" className="nav-link">Pools</Link>
									</li>									
								</ul>
							</div>
						</section>

						<div className="container sitecontainer stakedlppage">
							<div className="innerpagecontent">
								<div className="topcontentbox">
									<h3 className="heading-title pb-2">Stake LP tokens to earn</h3>
								</div>
							</div>
					
							<div className="panelcontentbox stakedtab">
								<div className="d-flex">					
									<div className="innerpagetab historytab">
										<ul className="nav nav-tabs tabbanner" role="tablist">
											<li className="nav-item"><a onClick={this.stakedtabfirst} className="nav-link active" data-bs-toggle="tab" href="#active" dat-bs-target="active">Live</a></li>
											<li className="nav-item"><a onClick={this.stakedtabmiddle} className="nav-link" data-bs-toggle="tab" href="#inactive" dat-bs-target="inactive">Finished</a></li>
											<li className="nav-item"><a onClick={this.stakedtablast} className="nav-link" data-bs-toggle="tab" href="#staked" dat-bs-target="staked">Staked</a></li>
										</ul>
									</div>

									<div className="cardlist">
										<ul className="nav nav-tabs">
											<li className="nav-item"><a className="nav-link active" data-bs-toggle="tab" href="#card" dat-bs-target="card"><i className="fa fa-bars" aria-hidden="true"></i><span>Card</span></a></li>
											<li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#list" dat-bs-target="card"><i className="fa fa-list" aria-hidden="true"></i><span>List</span></a></li>
										</ul>
									</div>
								</div>

								<div className="tabrightbox">
									<div className="supportsearch">
										<form className="siteformbox">
											<div className="form-group">
												<div className="input-group">
													<div className="input-group-append">
														<button type="submit" name="" className="input-group-text"><i className="fa fa-search"></i></button>
													</div>
													<input type="text" className="form-control" placeholder="Search Farms" />
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>

							<div className="tab-content">
								<div className="tab-pane fade in show active" id="card" role="tabpanel">
									<div className="tab-content">
										<div className="tab-pane fade in show active" id="active" role="tabpanel">
											<div className="row stackpage">
												{ (this.state.live.length > 0) && 
													this.state.live.map((data, i) => {
														return <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={i}>
															<div className="panelcontentbox">
																<div className="table-responsive" data-simplebar>
																	<table className="table sitetable">
																		<tbody>
																			<tr className="databottomrow">
																				<td colSpan={2} className="text-start">
																					<div className="stakeicon">
																						<div><img src={require('./../../assets/images/color/'+(data.pairData.token_one_name).toLowerCase()+'.svg')} className="coinicon" /><img src={require('./../../assets/images/color/'+(data.pairData.token_two_name).toLowerCase()+'.svg')} className="coinicon coinlefticon" /></div>
																						<div>{data.pairData.token_one_name}-{data.pairData.token_two_name}</div>
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td>APR<img src={apy} className="ms-2"/></td>
																				<td><span className="t-blue">{data.poolinfo.allocPoint}%</span></td>
																			</tr>
																			<tr>
																				<td>Staked LP</td>
																				<td><span className="t-blue">{ data.userInfo ? data.userInfo.amount : 0 }</span></td>
																			</tr>
																			<tr>
																				<td>Earned</td>
																				<td><span className="t-blue">{ data.userInfo ? data.userInfo.rewards : 0 }</span></td>
																			</tr>
																			<tr className="cellbutton">
																				<td colSpan={2} className="text-center">
																					{ !this.service.address() && 
																						<ConnectWallet name="menu" />
																					}

																					{
																						this.service.address() &&

																						<div className="d-flex">
																							{ ((data.userInfo && data.userInfo.amount > 0) && (data.pairData.allowance > 0)) &&
																								<>
																									<a onClick={ () =>this.filterStakeEvent(data, i) } className="btn sitebtn me-2">Add More</a>
																									<a onClick={ () =>this.filterWithdrawEvent(data, i) } className="btn sitebtn me-2">Withdraw</a>
																								</>
																							}

																							{ ((data.pairData.allowance > 0) && (data.userInfo && data.userInfo.amount == 0)) &&
																								<a onClick={ () =>this.filterStakeEvent(data, i) } className="btn sitebtn me-2">Stake LP</a>
																							}

																							{ (data.pairData.allowance == 0) &&
																								<button className="btn sitebtn me-2" onClick={ () => this.stakeApprove(data, i) }>Enable</button>
																							}
																						</div>
																					}
																				</td>
																			</tr>
																			<tr>
																				<td colSpan={2} className="text-center">
																				<hr/> <a href={`#liveviewcard-${i}`} className="alink moreicon" data-bs-toggle="collapse" data-bs-target={`#liveviewcard-${i}`}>More Details <img src={downarrow}/></a> </td>
																			</tr>
																			<tr className="buytradedata">
																				<td colSpan={2}>
																					<div id={`liveviewcard-${i}`} className="collapse">
																						<table className="table sitetable">
																							<tbody>							
																								<tr>
																									<td>Liquidity</td>
																									<td><span className="t-blue">1</span></td>
																								</tr>
																								<tr>
																									<td colSpan={2} className="text-center">
																										<a href={`${process.env.REACT_APP_ETHERSCAN}${data.pairData.pair_address}`} target="_blank" className="alink"><img src={arrow} className="me-2" />View Contract</a>
																									</td>						
																								</tr>
																							</tbody>
																						</table>
																					</div>
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</div>
															</div>
														</div>
													})
												}

												{ (this.state.live.length == 0) && 
													<div className="col-12 text-center">
														<div className="panelcontentbox">
															<div className="table-responsive" data-simplebar>
																<table className="table sitetable">
																	<tbody>
																		<tr className="nodata">
																			<td className="text-start">
																				{ this.state.isLiveLoading &&
																					<ReactLoading
																						type="bars"
																						color="#ff00c7"
																						height={'4%'}
																						width={'4%'}
																						className="no-record mx-auto"
																					/>
																				}

																				{ !this.state.isLiveLoading &&
																					<>
																						<img src={nodata} className="no-record" />
																						<span>No records found </span>
																					</>
																				}
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>	
												}
											</div>
										</div>

										<div className="tab-pane fade in" id="inactive" role="tabpanel">
											<div className="row stackpage lowopacity">
												{ (this.state.finished.length > 0) && 
													this.state.finished.map((data, i) => {
														return <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={i}>
															<div className="panelcontentbox">
																<div className="table-responsive" data-simplebar>
																	<table className="table sitetable">
																		<tbody>
																			<tr className="databottomrow">
																				<td colSpan={2} className="text-start">
																					<div className="stakeicon">
																						<div>
																							<img src={require('./../../assets/images/color/'+(data.token_one_name).toLowerCase()+'.svg')} className="coinicon" />
																							<img src={require('./../../assets/images/color/'+(data.token_two_name).toLowerCase()+'.svg')} className="coinicon coinlefticon" />
																						</div>
																						<div>{data.token_one_name}-{data.token_two_name}</div>
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td>APR<img src={apy} className="ms-2"/></td>
																				<td><span className="t-blue">{data.apr_perc}%</span></td>
																			</tr>
																			<tr>
																				<td>Staked LP</td>
																				<td><span className="t-blue">{ data.userInfo ? data.userInfo.amount : 0 }</span></td>
																			</tr>
																			<tr>
																				<td>Earned</td>
																				<td><span className="t-blue">{ data.userInfo ? data.userInfo.rewards : 0 }</span></td>
																			</tr>
																			<tr className="cellbutton">
																				<td colSpan={2} className="text-center">
																					<hr/> <a href="#view5" className="alink moreicon" data-bs-toggle="collapse" data-bs-target="#view5">More Details <img src={downarrow}/></a> </td>
																			</tr>
																			<tr className="buytradedata">
																				<td colSpan={2}>
																					<div id="view5" className="collapse">
																						<table className="table sitetable">
																						<tbody>							
																								<tr>
																									<td>Liquidity</td>
																									<td><span className="t-blue">${data.liquidity}</span></td>
																								</tr>
																								<tr>
																									<td colSpan={2} className="text-center">
																										<a href="#" className="alink"><img src={arrow} className="me-2" />View Contract</a>
																									</td>						
																								</tr>
																							</tbody>
																						</table>
																					</div>
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</div>
															</div>
														</div>
													})
												}

												{ (this.state.finished.length == 0) && 
													<div className="col-12 text-center">
														<div className="panelcontentbox">
															<div className="table-responsive" data-simplebar>
																<table className="table sitetable">
																	<tbody>
																		<tr className="nodata">
																			<td className="text-start">
																				<img src={nodata} className="no-record" />
																				<span>No records found </span>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>	
												}
											</div>
										</div>

										<div className="tab-pane fade in" id="staked" role="tabpanel">
											<div className="row stackpage">
												{ (this.state.staked.length > 0) && 
													this.state.staked.map((data, i) => {
														return  <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={i}>
															<div className="panelcontentbox">
																<div className="table-responsive" data-simplebar>
																	<table className="table sitetable">
																		<tbody>
																			<tr className="databottomrow">
																				<td colSpan={2} className="text-start">
																					<div className="stakeicon">
																						<div>
																							<img src={require('./../../assets/images/color/'+(data.pairData.token_one_name).toLowerCase()+'.svg')} className="coinicon" />
																							<img src={require('./../../assets/images/color/'+(data.pairData.token_two_name).toLowerCase()+'.svg')} className="coinicon coinlefticon" />
																						</div>
																						<div>{data.pairData.token_one_name}-{data.pairData.token_two_name}</div>
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td>APR<img src={apy} className="ms-2"/></td>
																				<td><span className="t-blue">{data.poolinfo.allocPoint}%</span></td>
																			</tr>
																			<tr>
																				<td>Staked LP</td>
																				<td><span className="t-blue">{ data.userInfo ? data.userInfo.amount : 0 }</span></td>
																			</tr>
																			<tr>
																				<td>Earned</td>
																				<td><span className="t-blue">{ data.userInfo ? data.userInfo.rewards : 0 }</span></td>
																			</tr>
																			<tr className="cellbutton">
																				<td colSpan={2} className="text-center">
																					<div className="d-flex">
																						<button className="btn sitebtn" onClick={() => this.filterUnstakeEvent(data, i)}>Unstake</button>
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td colSpan={2} className="text-center">
																					<hr/> <a href="#view9" className="alink moreicon" data-bs-toggle="collapse" data-bs-target="#view9">More Details <img src={downarrow}/></a>
																				</td>
																			</tr>
																			<tr className="buytradedata">
																				<td colSpan={2}>
																					<div id="view9" className="collapse">
																						<table className="table sitetable">
																							<tbody>
																								<tr>
																									<td>Liquidity</td>
																									<td><span className="t-blue">${data.liquidity}</span></td>
																								</tr>
																								<tr>
																									<td colSpan={2} className="text-center">
																										<a href="#" className="alink"><img src={arrow} className="me-2" />View Contract</a>
																									</td>						
																								</tr>
																							</tbody>
																						</table>
																					</div>
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</div>
															</div>
														</div>
													})
												}

												{ (this.state.staked.length == 0) && 
													<div className="col-12 text-center">
														<div className="panelcontentbox">
															<div className="table-responsive" data-simplebar>
																<table className="table sitetable">
																	<tbody>
																		<tr className="nodata">
																			<td className="text-start">
																				{this.state.isStakedLoading &&
																					<ReactLoading
																						type="bars"
																						color="#ff00c7"
																						height={'4%'}
																						width={'4%'}
																						className="no-record mx-auto"
																					/>
																				}

																				{ !this.state.isStakedLoading &&
																					<>
																						<img src={nodata} className="no-record" />
																						<span>No records found </span>
																					</>
																				}
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>	
												}
											</div>
										</div>
									</div>
								</div>

								<div className="tab-pane fade in" id="list" role="tabpanel">
									<div className="tab-content">
										<div className="tab-pane fade in show active" id="active" role="tabpanel">
											<div className="row stackpage stackcardbox">
												{ (this.state.live.length > 0) &&
													this.state.live.map((data, i) => {
														return <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={i}>
															<div className="panelcontentbox">
																<div className="table-responsive" data-simplebar>
																	<table className="table sitetable listtable">
																		<tbody>
																			<tr className="databottomrow">
																				<td colSpan={2} className="text-start">
																					<div className="stakeicon">
																						<div>
																							<img src={require('./../../assets/images/color/'+(data.pairData.token_one_name).toLowerCase()+'.svg')} className="coinicon" />
																							<img src={require('./../../assets/images/color/'+(data.pairData.token_two_name).toLowerCase()+'.svg')} className="coinicon coinlefticon" />
																						</div>
																						<div>{data.pairData.token_one_name}-{data.pairData.token_two_name} </div>
																					</div>
																				</td>
																			</tr>

																			<tr>
																				<td>APR<img src={apy} className="ms-2"/></td>
																				<td><span className="t-blue">{data.poolinfo.allocPoint}%</span></td>
																			</tr>
																			<tr>
																				<td>Staked LP</td>
																				<td><span className="t-blue">{ data.userInfo ? data.userInfo.amount : 0}</span></td>
																			</tr>
																			<tr>
																				<td>Earned</td>
																				<td><span className="t-blue">{data.userInfo ? data.userInfo.rewards : 0}</span></td>
																			</tr>
																			<tr className="cellbutton">
																				<td colSpan={2} className="text-center">
																					{ !this.service.address() && 
																						<ConnectWallet name="menu" />
																					}

																					{
																						this.service.address() &&

																						<div className="d-flex">
																							{ ((data.userInfo && data.userInfo.amount > 0) && (data.pairData.allowance > 0)) &&
																								<>
																									<a onClick={ () =>this.filterStakeEvent(data, i) } className="btn sitebtn me-2">Add More</a>
																									<a onClick={ () =>this.filterWithdrawEvent(data, i) } className="btn sitebtn me-2">Withdraw</a>
																								</>
																							}

																							{ ((data.pairData.allowance > 0) && (data.userInfo && data.userInfo.amount == 0)) &&
																								<a onClick={ () =>this.filterStakeEvent(data, i) } className="btn sitebtn me-2">Stake LP</a>
																							}

																							{ (data.pairData.allowance == 0) &&
																								<button className="btn sitebtn me-2" onClick={ () => this.stakeApprove(data, i) }>Enable</button>
																							}
																						</div>
																					}
																				</td>	
																			</tr>											
																			<tr className="moredetailsicon">
																				<td colSpan={2} className="text-center">
																					<hr/> <a href="#viewlist1" className="alink moreicon" data-bs-toggle="collapse" data-bs-target="#viewlist1">More Details <img src={downarrow}/></a> </td>
																			</tr>
																			<tr className="buytradedata">
																				<td colSpan={2}>
																					<div id="viewlist1" className="collapse">
																						<table className="table sitetable">
																							<tbody>				
																								<tr className="visibilityhidden">
																									<td colSpan={2}></td>
																								</tr>			
																								<tr>
																									<td>Liquidity</td>
																									<td><span className="t-blue"></span></td>
																								</tr>
																								<tr className="visibilityhidden">
																									<td colSpan={2}></td>
																								</tr>
																								<tr className="visibilityhidden">
																									<td colSpan={2}></td>
																								</tr>	
																								<tr className="cellbutton linkbutton">
																									<td className="text-center">
																										<a href={`${process.env.REACT_APP_ETHERSCAN}${data.pairData.pair_address}`} className="alink"><img src={arrow} className="me-2" />View Contract</a>
																									</td>							
																								</tr>
																								<tr className="moredetailsicon visibilityhidden">
																									<td colSpan={2}></td>
																								</tr>
																							</tbody>
																						</table>
																					</div>
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</div>
															</div>
														</div>
													})
												}

												{ (this.state.live.length == 0) && 
													<div className="col-12 text-center">
														<div className="panelcontentbox">
															<div className="table-responsive" data-simplebar>
																<table className="table sitetable listtable">
																	<tbody>
																		<tr className="nodata">
																			<td className="text-start">
																				<img src={nodata} className="no-record" />
																				<span>No records found </span>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>	
												}
											</div>
										</div>
										<div className="tab-pane fade in" id="inactive" role="tabpanel">
											<div className="row stackpage stackcardbox lowopacity">
												{ (this.state.finished.length > 0) &&
													this.state.finished.map((data, i) => {
														return <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={i}>
															<div className="panelcontentbox">
																<div className="table-responsive" data-simplebar>
																	<table className="table sitetable listtable">
																		<tbody>
																			<tr className="databottomrow">
																				<td colSpan={2} className="text-start">
																					<div className="stakeicon">
																						<div>
																							<img src={require('./../../assets/images/color/'+(data.token_one_name).toLowerCase()+'.svg')} className="coinicon" />
																							<img src={require('./../../assets/images/color/'+(data.token_two_name).toLowerCase()+'.svg')} className="coinicon coinlefticon" />
																						</div>
																						<div>{data.token_one_name}-{data.token_two_name} </div>
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td>APR fi<img src={apy} className="ms-2"/></td>
																				<td><span className="t-blue">{data.apr_perc}%</span></td>
																			</tr>
																			<tr>
																				<td>Staked LP</td>
																				<td><span className="t-blue">{ data.userInfo ? data.userInfo.amount : 0}</span></td>
																			</tr>
																			<tr>
																				<td>Earned</td>
																				<td><span className="t-blue">{data.userInfo ? data.userInfo.rewards : 0}</span></td>
																			</tr>
																			<tr className="cellbutton moredetailsicon">
																				<td colSpan={2} className="text-center">
																					<hr/> <a href="#viewlist5" className="alink moreicon" data-bs-toggle="collapse" data-bs-target="#viewlist5">More Details <img src={downarrow}/></a>
																				</td>
																			</tr>
																			<tr className="buytradedata">
																				<td colSpan={2}>
																					<div id="viewlist5" className="collapse">
																						<table className="table sitetable">
																						<tbody>	
																								<tr className="visibilityhidden">
																									<td colSpan={2}></td>
																								</tr>			
																								<tr>
																									<td>Liquidity</td>
																									<td><span className="t-blue">${data.liquidity}</span></td>
																								</tr>
																								<tr className="visibilityhidden">
																									<td colSpan={2}></td>
																								</tr>
																								<tr className="visibilityhidden">
																									<td colSpan={2}></td>
																								</tr>	
																								<tr className="cellbutton linkbutton">
																									<td className="text-center">
																										<a href="#" className="alink"><img src={arrow} className="me-2" />View Contract</a>
																									</td>							
																								</tr>
																								<tr className="moredetailsicon visibilityhidden">
																									<td colSpan={2}></td>
																								</tr>
																							</tbody>
																						</table>
																					</div>
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</div>
															</div>
														</div>
													})
												}

												{ (this.state.finished.length == 0) && 
													<div className="col-12 text-center">
														<div className="panelcontentbox">
															<div className="table-responsive" data-simplebar>
																<table className="table sitetable listtable">
																	<tbody>
																		<tr className="nodata">
																			<td className="text-start">
																				<img src={nodata} className="no-record" />
																				<span>No records found </span>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>	
												}
											</div>
										</div>
										<div className="tab-pane fade in" id="staked" role="tabpanel">
											<div className="row stackpage stackcardbox">
												{(this.state.staked.length > 0) && 
													this.state.staked.map((data, i) => {
														return <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={i}>
															<div className="panelcontentbox">
																<div className="table-responsive" data-simplebar>
																	<table className="table sitetable listtable">
																		<tbody>
																			<tr className="databottomrow">
																				<td colSpan={2} className="text-start">
																					<div className="stakeicon">
																						<div>
																							<img src={require('./../../assets/images/color/'+(data.pairData.token_one_name).toLowerCase()+'.svg')} className="coinicon" />
																							<img src={require('./../../assets/images/color/'+(data.pairData.token_two_name).toLowerCase()+'.svg')} className="coinicon coinlefticon" />
																						</div>
																						<div>{data.pairData.token_one_name}-{data.pairData.token_two_name} </div>
																					</div>
																				</td>
																			</tr>
																			<tr>
																				<td>APR Stake<img src={apy} className="ms-2"/></td>
																				<td><span className="t-blue">{data.poolinfo.allocPoint}%</span></td>
																			</tr>
																			<tr className="">
																				<td>Staked LP</td>
																				<td><span className="t-blue">{ data.userInfo ? data.userInfo.amount : 0}</span></td>
																			</tr>
																			<tr className="">
																				<td>Earned</td>
																				<td><span className="t-blue">{data.userInfo ? data.userInfo.rewards : 0}</span></td>
																			</tr>
																			
																			<tr className="cellbutton">
																				<td colSpan={2} className="text-center">
																					<div className="d-flex"> <a href="#stakelp" className="btn sitebtn me-2" data-bs-toggle="modal" data-bs-target="#stakelp">Stake LP</a> <a href="#unstakelp" className="btn sitebtn" data-bs-toggle="modal" data-bs-target="#unstakelp">Unstaked</a> 
																				</div>														
																				</td>
																			</tr>
																			
																			<tr className="moredetailsicon">
																				<td colSpan={2} className="text-center">
																					<hr/> <a href="#viewlist9" className="alink moreicon" data-bs-toggle="collapse" data-bs-target="#viewlist9">More Details <img src={downarrow}/></a> </td>
																			</tr>
																			<tr className="buytradedata">
																				<td colSpan={2}>
																					<div id="viewlist9" className="collapse">
																						<table className="table sitetable">
																							<tbody>
																								<tr className="visibilityhidden">
																									<td colSpan={2}></td>
																								</tr>			
																								<tr>
																									<td>Liquidity</td>
																									<td><span className="t-blue">${data.liquidity}</span></td>
																								</tr>
																								<tr className="visibilityhidden">
																									<td colSpan={2}></td>
																								</tr>
																								<tr className="visibilityhidden">
																									<td colSpan={2}></td>
																								</tr>	
																								<tr className="cellbutton linkbutton">
																									<td className="text-center"><a href="#" className="alink"><img src={arrow} className="me-2" />View Contract</a></td>
																								</tr>
																								<tr className="moredetailsicon visibilityhidden">
																									<td colSpan={2}></td>
																								</tr>
																							</tbody>
																						</table>
																					</div>
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</div>
															</div>
														</div>
													})
												}

												{ (this.state.staked.length == 0) &&
													<div className="col-12 text-center">
														<div className="panelcontentbox">
															<div className="table-responsive" data-simplebar>
																<table className="table sitetable listtable">
																	<tbody>
																		<tr className="nodata">
																			<td className="text-start">
																				<img src={nodata} className="no-record" />
																				<span>No records found </span>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<Modal
							show={this.state.isShowStake}
							onHide={() => this.showHideStakePopup}
							size="sm"
							dialogClassName="modalbgt"
							aria-labelledby="contained-modal-title-vcenter"
							centered
						>
							<Modal.Header>
								<Modal.Title id="contained-modal-title-vcenter">Stake LP Tokens</Modal.Title>
								<button className="close" onClick={this.showHideStakePopup}>&times;</button>
							</Modal.Header>
							<Modal.Body>
								<div className="siteformbox">
									<div className={`bluedarkbox ${this.state.isStakeLoading ? 'common-loading pe-none' : ''} `}>
										<table className="table sitetable mb-0">
											<tbody>
												<tr>
													<td>Stake</td>
													<td className="text-end">Balance : { this.state.filterStakePairBal} { this.state.filterStakePairTxt} </td>
												</tr>
												<tr>
													<td>
														<input className="form-control form-control-lg" value={this.state.stakeAmt} onChange={this.stakeAmtCalInputEvent} pattern="[+-]?\d+(?:[.,]\d+)?" />
													</td>
													<td className="text-end">
														<span className="d-flex pull-right">
															<span>
																<ul className="settinglimit">
																	<li><a>Max</a></li>
																</ul>
															</span>
															<span>{ this.state.filterStakePairTxt}</span>
														</span>
													</td>
												</tr>
											</tbody>
										</table>
									</div>

									{ this.state.isStakeLoading && 
										<ReactLoading
											type="bars"
											color="#ff00c7"
											height={'6%'}
											width={'6%'}
											className="mx-auto"
										/>
									}

									{
										this.state.stakeAlert && 
										<div className="alert-org-color alert alert-danger "><i className="fa fa-info-circle"></i> Insufficient {this.state.stakeAmt} Balance </div>
									}

									{
										(!this.state.stakeAlert && this.state.isShowStakeAllowence) &&
										<div className="alert-org-color alert alert-danger "><i className="fa fa-info-circle"></i> You can stake with in the {this.state.stakeAllowence} Amount </div>
									}

									<div className={`text-center mt-3 stakebtnrow d-flex ${this.state.isStakeLoading ? 'common-loading pe-none' : ''} `}>
										<a onClick={() => this.showHideStakePopup()} className="btn border-btn me-2">Cancel</a>
										<a onClick={() => this.confirmEvent()} className={`btn sitebtn ${ (this.state.stakeAlert || this.state.isShowStakeAllowence || (this.state.stakeAmt == 0) ) ? 'common-loading pe-none' : '' } `}>Confirm</a>
									</div>
								</div>
							</Modal.Body>
						</Modal>

						<Modal
							show={this.state.isShowWithdraw}
							onHide={() => this.showHideWithdrawPopup}
							size="sm"
							dialogClassName="modalbgt"
							aria-labelledby="contained-modal-title-vcenter"
							centered
						>
							<Modal.Header>
								<Modal.Title id="contained-modal-title-vcenter">Stake LP Tokens Withdraw</Modal.Title>
								<button className="close" onClick={this.showHideWithdrawPopup}>&times;</button>
							</Modal.Header>
							<Modal.Body>
								<div className="siteformbox">
									<div className={`bluedarkbox ${this.state.isWithdrawLoading ? 'common-loading pe-none' : ''} `}>
										<table className="table sitetable mb-0">
											<tbody>
												<tr>
													<td>Withdraw</td>
													<td className="text-end">Balance : { this.state.filterWithdrawPairBal} { this.state.filterWithdrawPairTxt} </td>
												</tr>
												<tr>
													<td>
														<input className="form-control form-control-lg" value={this.state.withdrawAmt} onChange={this.withdrawAmtCalInputEvent} pattern="[+-]?\d+(?:[.,]\d+)?" />
													</td>
													<td className="text-end">
														<span className="d-flex pull-right">
															<span>
																<ul className="settinglimit">
																	<li><a>Max</a></li>
																</ul>
															</span>
															<span>{ this.state.filterWithdrawPairTxt}</span>
														</span>
													</td>
												</tr>
											</tbody>
										</table>
									</div>

									{ this.state.isWithdrawLoading && 
										<ReactLoading
											type="bars"
											color="#ff00c7"
											height={'6%'}
											width={'6%'}
											className="mx-auto"
										/>
									}

									{
										this.state.isWithdrawAlert && 
										<div className="alert-org-color alert alert-danger "><i className="fa fa-info-circle"></i> Insufficient {this.state.withdrawAmt} Balance </div>
									}

									<div className={`text-center mt-3 stakebtnrow d-flex ${this.state.isWithdrawLoading ? 'common-loading pe-none' : ''} `}>
										<a onClick={() => this.showHideWithdrawPopup()} className="btn border-btn me-2">Cancel</a>
										<a onClick={() => this.confirmWithdrawEvent()} className={`btn sitebtn ${ (this.state.isWithdrawAlert || this.state.withdrawAmt <= 0) ? 'common-loading pe-none' : '' } `}>Confirm</a>
									</div>
								</div>
							</Modal.Body>
						</Modal>

						<Modal
							show={this.state.isShowUnstake}
							onHide={() => this.showHideUnstakePopup}
							size="sm"
							dialogClassName="modalbgt"
							aria-labelledby="contained-modal-title-vcenter"
							centered
						>
							<Modal.Header>
								<Modal.Title id="contained-modal-title-vcenter">Unstaked</Modal.Title>
								<button className="close" onClick={this.showHideUnstakePopup}>&times;</button>
							</Modal.Header>
							<Modal.Body>
								<div className="siteformbox text-center">
									<img src={lock}className="lockicon" />
									<p className="text-center">You agree to { process.env.REACT_APP_NAME } Terms of Service and acknowledge that you have read and understand the { process.env.REACT_APP_NAME } Protocol Disclaimer. </p>

									{ this.state.isUnstakeLoading && 
										<ReactLoading
											type="bars"
											color="#ff00c7"
											height={'6%'}
											width={'6%'}
											className="mx-auto"
										/>
									}

									<div className={`text-center mt-3 stakebtnrow d-flex ${this.state.isUnstakeLoading ? 'common-loading pe-none' : ''} `}>
										<button className="btn border-btn me-2" onClick={() => this.showHideUnstakePopup() }>Cancel</button>
										<button className="btn sitebtn" onClick={() => this.unstakeConfirmEvent() }>Confirm</button>
									</div>
								</div>
							</Modal.Body>
						</Modal>

						{ <StakeUtil
							ref={this.stakeUtilRef}
							clickHandlerStakeApprove={this.stakeApproved}
							clickHandlerDepositError={this.stakeWarning}
							clickHandler={this.stakeConfirmed}
							clickHandlerWithdraw={this.withdrawConfirmed}
							clickHandlerUnStake={this.unStakeConfirmed}
						></StakeUtil>}

						<ToastContainer autoClose={5000} />
					</article>
				</div>
	}
}

export default Farms;